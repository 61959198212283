<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
        <div class="modal-container">
            <div id="yearModal">
                <div>
                    Précisez l'année d'études                  
                    <label  for="year" class="custom-select select2">
                    <select class="select2 simBtns"  name="year" v-model="year">
                        <option :value="1">1</option>
                        <option :value="2">2</option>
                        <option :value="3">3</option>
                        <option :value="4">4</option>
                        <option :value="5">5</option>
                        <option :value="'>5'">Supérieure à 5</option>
                    </select>
                    </label>
                </div>
                <div>
                    <button class="button2" :disabled='!year' @click="submit()">Envoyer</button>
                </div>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
// import axios from 'axios'
// import config from '../../../config.js'

export default {
  name:"updateYearModal",
  props:['newRole', 'index'],
  data(){
    return {
        year:false,
    }
  },
  methods:{
        submit(){
            this.$parent.updateUser(this.index, this.newRole, this.year)
            this.$parent.displayYearModal=false
        }
    }
} 
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 90%;
  height: 80%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  overflow:auto;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

input[type="number"]{
  width:140px;
}
.paramInput{
  text-align:center;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
th, td{
  width:25%;
}
#yearModal{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.error{
    color:red;
}
@media (max-width: 1000px) {
#yearModal{
  display: block;
  margin:auto;
}
}
</style>
<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container">
                <div class="modal-footer">
                    <button class="modal-default-button" @click="close()">X</button>
                </div>
                <div id="contactBody">
                    <h1>Contacts</h1>
                        <p>Dairy Health Sim est un simulateur de troupeau bovin laitier et de ses caractéristiques zootechniques et sanitaires, financé par l'ANR via Agreenium, par l'Envt, et avec la participation du plan EcoAntibio.</p>
                        <p>En savoir plus: <a href="https://veteconomics.envt.fr/" target="_blank"> site de VetEconomics</a></p>
                        <p><b>Ahmed Ferchiou</b> - Ingénieur de recherche - Chef de projet, conceptualisation et modélisation du système de production - <a href="mailto:ahmed.ferchiou@envt.fr">ahmed.ferchiou@envt.fr</a></p>
                        <p><b>Didier Raboisson</b> - Professeur, Docteur Vétérinaire - Responsable de projet, conceptualisation - <a href="mailto:didier.raboisson@envt.fr">didier.raboisson@envt.fr</a></p>
                </div>
                <div id="historyBody">
                    <h1>Historique des mises à jour</h1>
                        <p><b>18/08/2021</b></p>
                        <ul>
                          <li>Correction de bugs graphiques</li>
                        </ul>

                </div>
            </div>
        </div>
    </div>    
</template>

<script>
// import axios from 'axios'
// import config from '../../../config.js'
// import updateYearModal from './updateYearModal.vue'

export default {
  name:"contactModal",
  components:{
  },
  data(){
    return {

    }
  },
  created(){
  },
  methods:{
        close(){
            this.$parent.displayContactModal = false
        },
  }
} 
</script>

<style scoped>
#contactBody, #historyBody{
    text-align:left;
    text-indent: 0em !important;
}
#contactBody p{
    text-indent: 0em !important;
}
#historyBody{
  margin-top: 30px;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-container {
  width: 90%;
  height: unset !important;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  overflow:auto;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
<template>
    <div class="logoFooter">
      <VueSlickCarousel v-if="displayCarousel" v-bind="settings">
          <div class="tbd">
            <img class="footer-img" src="../assets/ENVT-logo-RVB.png">
          </div>
          <div class="tbdc">
            <img class="footer-img" src="../assets/veteconomicsLogo.png">
          </div>
          <div class="tbdc">
            <img class="footer-img2" src="../assets/Agence_Nationale_de_la_Recherche.svg" >
          </div>
          <div class="tbdc">
            <img class="footer-img" src="../assets/agreenium_logo_rvb.png">
          </div>
          <div class="tbd">
            <img class="footer-img2" src="../assets/Logo-INRAE_Transparent.svg">
          </div>
        </VueSlickCarousel>
        <div v-else class="carouselElt">
          <div class="tbd">
            <img class="footer-img" src="../assets/ENVT-logo-RVB.png">
          </div>
          <div class="tbdc">
            <img class="footer-img" src="../assets/veteconomicsLogo.png">
          </div>
          <div class="tbdc">
            <img class="footer-img2" src="../assets/Agence_Nationale_de_la_Recherche.svg" >
          </div>
          <div class="tbdc">
            <img class="footer-img" src="../assets/agreenium_logo_rvb.png">
          </div>
          <div class="tbd">
            <img class="footer-img2" src="../assets/Logo-INRAE_Transparent.svg">
          </div>
        </div>
    </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'

export default {
  name: 'logoFooter',
  components: { VueSlickCarousel },
  async mounted(){
    window.onresize = () => {
       this.carouselMgmt()
    }
  }, 
  created() {
    this.carouselMgmt()
  },
  data(){
    return{
      displayCarousel:true,
      settings:{
        "adaptiveHeight	":true,
        "arrows":true,
        "edgeFriction": 0.35,
        "infinite": false,
        "speed": 500,
        "slidesToShow": 1,
        "slidesToScroll": 1
      }
    }
  },
  methods:{
    carouselMgmt(){
      window.innerWidth < 850? this.displayCarousel=true : null
      window.innerWidth > 850? this.displayCarousel=false : null
    },
  }
}
</script>

<style>
.slick-prev:before, .slick-next:before { 
    color:black !important;
}

.slick-slider {
    width: 70%;
}
.slick-track {
  display: flex;
}
.slick-track .slick-slide {
  display: flex;
  height: auto;
  align-items: center;
  justify-content: center;
}
.logoFooter{
  display: flex;
  justify-content: space-around;
  place-items: center;
  position: fixed;
  bottom:0px;
  left: 0px;
  height: 70px;
  width: 100%;
  z-index:2;
  background-color: white;
  border-style: solid none none none;
  border-width: 2px 0 0 0;
}
.carouselElt{
  width:100%;
  display: flex;
  justify-content: space-around;
  place-items: center;
}
.footer-img{
  width:150px;
}
.footer-img2{
  width:130px;
}
.tbd{
  padding: 10px 0 !;
}
/* .slick-initialized .slick-slide {
    display: flex;
    justify-content: center;
} */
/* .tbd, .tbdc{
  display: flex;
    justify-content: center;
} */
@media (max-width: 710px) {
/* .footer-img{
  width:120px;
}
.footer-img2{
  width:112px;
} */
}
</style>
<template>
  <div id="simPage" v-bind:class="{ backgroundBugFix: !displayUserPage }">
  <!-- <div id="simPage" v-bind:class="{ backgroundBugFix: !$store.state.moodleLogged || !displayUserPage }">-->
    <div class="logoItem">
      <img class="sim-logo" src="../assets/logo complet light.png">
      <!-- <p v-if="$store.state.moodleUserFullName != false">Bonjour {{$store.state.moodleUserFullName}}</p> -->
    </div> 
    <div id="navBar">
      <div id="runningSimItem">
        <div v-if="simCount!=0" id="simCount" @click="displayUserPage=false, displayScenarioPage=false, displayRunningSimModal =!displayRunningSimModal"><span class="mozFix">{{simCount}}</span></div>
        <img id="runningSimImg" src="../assets/bulb.png" @click="displayUserPage=false, displayScenarioPage=false, displayRunningSimModal =!displayRunningSimModal"/>
      </div>
      <div id="userPageItem" :class="[displayUserPageToggle ? 'activate' : 'desactivate' ]" >
        <img id="userImg" src="../assets/userPageIcon.png" @click="displayUserPage=!displayUserPage, displayUserPageToggle=!displayUserPageToggle, displayScenarioPage=false">
      </div>
      <div id="contactItem" >
          <img id="contactImg" src="../assets/contact-info.png" @click="displayContactModal=!displayContactModal">
      </div>
      <div id="logoutItem" >
          <img id="logoutImg" src="../assets/logout.png" @click="logout()">
      </div>
    </div>
    <transition name="component-fade" mode="out-in">
    <div id="simFlex" v-if="!displayUserPage && !displayScenarioPage && !displayRunningSimModal">
        <div class="simChild">
          <h2>Sélectionnez les résultats d'une simulation précédente</h2>
          <div v-if="$store.state.status =='Administrateur'">
             <label class="custom-select12">
            <select class="schoolSelect" @change="get_sims()"  v-model="selectedSchool">
              <option value="ENVT" selected>ENVT</option>
              <option value="Agreenium">Agreenium</option>
              <option value="Autre">Autre</option>
            </select>
          </label>
          </div>
          <ul>
            <li class="previousSims" v-for="(item, index) in availableSims" v-bind:key="item" @click="getPreviousScenario(availableSims[index])">
              {{item}}
            </li>
          </ul>
          <div v-if="$store.state.status =='Administrateur'">
            <button class="button simBtns" @click="deletePreviousSims()">Effacer toutes les simulations pour cette école</button>
          </div>
        </div>
        <div id="or">
          <h2>Ou</h2>
        </div>
        <div class="simChild">
            <div ><h2>Lancez le calcul d'une nouvelle simulation</h2></div>
            <div class="simChildSubDiv">
                <p v-if="$store.state.disableSim">Délai d'attente estimé pour obtenir les résultats: {{waitingTime}} minutes</p>
                <p class="error" v-if="!$store.state.disableSim">Le lancement de nouvelles simulations est actuellement bloqué à cause du trop grand nombre de demandes. Veuillez vous reconnecter ultérieurement.</p>
                <button class="button simBtns" :disabled='!$store.state.disableSim' @click="displayScenarioPage = true">Choisir un scénario</button>
            </div>
        </div>
    </div>
    <scenarioPage v-if="displayScenarioPage" />
    <userPage v-if="displayUserPage" />
    <simWaitList v-if="displayRunningSimModal" />
    </transition>
    <LogoFooter />
    <contactModal v-show="displayContactModal" />
  </div>
</template>

<script>
import axios from 'axios'
import LogoFooter from './footer.vue'
import config from '../../config.js'
import contactModal from './modals/aboutUs.vue'
import userPage from './userPage.vue'
import scenarioPage from './scenario.vue'
import simWaitList from './modals/simWaitList.vue'
export default {
    name:'simulationPage',
    components: {
        LogoFooter,
        userPage,
        contactModal,
        scenarioPage,
        simWaitList
    },
    data(){
      return {
        availableSims:"",
        scenarii:[],
        defaultScenario:"",
        dataset:"",
        selectedScenario:"S_T1_A1_HB0C0",
        displayUserPage:false,
        displayUserPageToggle:false,
        displayContactModal:false,
        displayScenarioPage:false,
        displayRunningSimModal:false,
        simCount:0,
        waitingTime:5,
        selectedSchool:"ENVT"
      }
    },
    created(){
      //vérifier s'il est possible de lancer une simulation
      this.canSimCheck()
      //récupérer les simulations en cours s'il y en a
      this.simRunningCheck()
    },
    async mounted(){   
      this.$root.$on('checkSimState', (id) => {
        this.checkSimState(id)
      }),
      await this.get_config()
      await this.get_sims()
      await this.get_json_data()
    },
    methods:{
      get_config(){
        axios.get(config.serverUrl+"/get_config/")    
                    .then(response => {
                      this.selectedScenario = response.data.defaultScenario
                      this.scenarii = response.data.scenarios
                    })
                    .catch(e => {
                        console.log(e)
                    })
      },
      get_sims(){
        var institutionToDisplay
        if(this.$store.state.status =='Administrateur'){
          institutionToDisplay = this.selectedSchool
        }else if(localStorage.getItem('userSession') == null){
          institutionToDisplay = JSON.parse(localStorage.getItem('logDate'))[0][8]
        }else{
          institutionToDisplay = JSON.parse(localStorage.getItem('userSession')).value[0][7]
        }
        
        axios.post(config.serverUrl+"/get_available_sims/",                 
        {
          institution: institutionToDisplay,
        },         
        {  headers: { 'content-type': 'text/plain' }
        })    
        .then(async response => {
          this.availableSims = response.data.filter(x => x!=".gitignore")
        })
        .catch(e => {
            console.log(e)
        })
      },
      async getPreviousScenario(scenario){
        await this.$store.commit("SELECT_PREVIOUS_SIM", scenario)
      },
      async deletePreviousSims(){
        if(window.confirm("Etes-vous sur de vouloir effacer ces simulations?")){
          await axios.get(config.serverUrl+"/delete_all_sims/?school="+this.selectedSchool)   
          this.get_sims()
        }
      },
      async get_json_data(){
        //Si l'utilisateur arrive depuis moodle, il n'a pas de userSession et le compte n'est pas validé, donc il ne peut pas utiliser les json personnalisés. 
        // s'il n'y a pas de userSession, on ne récupère pas les jsons depuis le fichier personnalisé de l'utilisateur -> moodleCheck est nul. Dans le cas contraire, moodleCheck prend la valeur de l'email utilisateur pour récupérer les jsons persos
        let moodleCheck = ""
        var token = localStorage.getItem('userSession')
        if (token !== null) {
          var item = JSON.parse(token)
           moodleCheck = item.value[0][0]
        } else {
          moodleCheck = null
        }
        axios.get(config.serverUrl+"/get_json_data/?json="+this.$store.state.jsonFile+"&email="+moodleCheck)
          .then(response=>{
            this.$store.commit("GET_JSON_DATA", response.data)
          })
          .catch(e=>{console.log(e)})
      },
      logout(){
        localStorage.removeItem('userSession')
        this.$store.commit('LOGGED')
      },
      simRunningCheck(){
        if(localStorage.getItem("runningSim")!=null){
          let simSession = JSON.parse(localStorage.getItem("runningSim"))
          this.simCount = simSession.length
          if (simSession.length != 0) {
            for (let index = 0; index < simSession.length; index++) {
              this.checkSimState(simSession[index]["id"]) 
            }
          }
        }
      },
/*
      checkSimState(id){
            let vm = this
            this.$store.dispatch("CHECK_SIM_STATE", id)
            .then(payload => {
                if(payload.result.simState == "SUCCESS"){
                    this.$root.$emit('removeFinishedSim', id)
                    this.$store.commit("DISPLAY_SIM_AVAILABLE_MODAL", payload.simName)
                    this.get_sims()
                    this.editSimCount(-1)
                    this.getWaitingTime()
                    //this.$root.$emit('editListAfterStop', id)
                } else if(payload.result.simState == "REVOKED"){
                    // la simulation a été arrêtée : on regarde si elle est toujours dans local storage. Si oui, cela veut dire que l'admin l'a annulée : on la supprime, on édite la liste et on notifie l'utilisateur
                    var runningSim = JSON.parse(localStorage.getItem("runningSim"))
                    for (let index = 0; index < runningSim.length; index++) {
                            if(runningSim[index].id === id){
                              this.$store.commit("EDIT_FEEDBACK_MSG", "La simulation "+runningSim[index].name+" a été stoppée. Veuillez en refaire une ultérieurement.")
                              this.$store.commit("DISPLAY_FEEDBACK_MODAL")
                              this.editSimCount(-1)
                              this.$root.$emit('editListAfterStop', id) 
                              this.getWaitingTime()
                              runningSim.splice(index, 1);
                              localStorage.setItem("runningSim", JSON.stringify(runningSim))
                            }   
                    }
                } else if (payload.result.simState == "PENDING" || payload.result.simState == "STARTED"){
                    if(payload.newState == true){this.$root.$emit('updateSimState', payload.result)}
                    setTimeout(function(){
                        vm.checkSimState(id)
                    },10000); 
                }
            })
            .catch(e=>{
                console.log(e)
                this.$store.commit("EDIT_FEEDBACK_MSG", "Un problème est survenu. (simulationPage.vue)")
                this.$store.commit("DISPLAY_FEEDBACK_MODAL")
            })            
      }, 
*/
checkSimState(id){
    let vm = this
    this.$store.dispatch("CHECK_SIM_STATE", id)
    .then(payload => {
        // Vérification que 'result' et 'simState' existent dans 'payload'
        if (payload && payload.result && payload.result.simState) {
            if (payload.result.simState == "SUCCESS") {
                this.$root.$emit('removeFinishedSim', id)
                this.$store.commit("DISPLAY_SIM_AVAILABLE_MODAL", payload.simName)
                this.get_sims()
                this.editSimCount(-1)
                this.getWaitingTime()
            } else if (payload.result.simState == "REVOKED") {
                var runningSim = JSON.parse(localStorage.getItem("runningSim"))
                for (let index = 0; index < runningSim.length; index++) {
                    if (runningSim[index].id === id) {
                        this.$store.commit("EDIT_FEEDBACK_MSG", "La simulation " + runningSim[index].name + " a été stoppée. Veuillez en refaire une ultérieurement.")
                        this.$store.commit("DISPLAY_FEEDBACK_MODAL")
                        this.editSimCount(-1)
                        this.$root.$emit('editListAfterStop', id)
                        this.getWaitingTime()
                        runningSim.splice(index, 1);
                        localStorage.setItem("runningSim", JSON.stringify(runningSim))
                    }
                }
            } else if (payload.result.simState == "PENDING" || payload.result.simState == "STARTED") {
                if (payload.newState == true) {
                    this.$root.$emit('updateSimState', payload.result)
                }
                setTimeout(function () {
                    vm.checkSimState(id)
                }, 10000);
            }
        } else {
            // Logger un avertissement dans la console si 'result' est indéfini
            console.warn("Avertissement: 'result' est indéfini dans 'payload'", payload);
        }
    })
    .catch(e => {
        console.log(e)
        this.$store.commit("EDIT_FEEDBACK_MSG", "Un problème est survenu. (simulationPage.vue)")
        this.$store.commit("DISPLAY_FEEDBACK_MODAL")
    })            
},

      editSimCount(number){
          this.simCount = this.simCount+number
      },
      canSimCheck(){
        let vm = this
          var institution
          if(localStorage.getItem('userSession') == null){
              institution = JSON.parse(localStorage.getItem('logDate'))[0][8]
          }else{
              var item = JSON.parse(localStorage.getItem('userSession'))
              institution = item.value[0][7]
          }
          axios.get(config.serverUrl+"/can_sim/?institution="+institution)
          .then(response=>{
            if (response.data.enableSim) {
              this.$store.commit("DISABLE_NEW_SIM", true)
              this.getWaitingTime()
            } else{
              this.$store.commit("DISABLE_NEW_SIM", false)
              // requête pour mettre à jour l'interface après déblocage
              setTimeout(function(){
                vm.canSimCheck()
              },10000); 
            }
          })
          .catch(e=>{console.log(e)})
      },
      getWaitingTime(){
         axios.get(config.serverUrl+"/get_waiting_time/")
          .then(response=>{
            //console.log(response.data)
            this.waitingTime = 5+Math.ceil(5*(response.data.waitingTime/response.data.cpuCount))
          })
          .catch(e=>{console.log(e)})

      },
      updateSimsToDisplay(school){
          console.log(school)
      }
    }
}
</script>

<style>
.sim-logo{
  width:200px;
  padding-top:5px;
}
#simPage{
  height: 100%;
  background: url("../assets/fond-troupeau2.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position-y: 100%;
}
#userImg{
  width:40px;
}
#navBar{
  position: absolute;
  right: 25px;
  top: 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 49px;
  width: 200px;
}
#userPageItem{
  width:40px;
  height:40px;
  z-index: 2;
  cursor: pointer;
}
#contactImg{
  width:40px;
}
#runningSimImg{
    width:40px;

}
#runningSimItem{
  /* width: 40px;
  position: relative;
  top: -8px;
  height: 79px;
  z-index: 2;
  cursor: pointer; */

      width: 40px;
    /* height: 79px; */
    position: relative;
    top: 0;
    left: 0;
    cursor: pointer;
}
#contactItem{
  width:40px;
  height:40px;
  z-index: 2;
  cursor: pointer;
}
#logoutItem{
  width:40px;
  height:40px;
  z-index: 2;
  cursor: pointer; 
}
#logoutImg{
  width:40px;
}
.simChild > h2 {
  margin-block-start: 0.5em;
  margin-block-end: 0.5em ;
}
.activate{
  transition: all 0.9s ease-in-out 0s;
  transform: rotate(90deg);
}
.desactivate{
  transition: all 0.9s ease-in-out 0s;
  transform: rotate(-90deg);
}
#simFlex{
  height: 80%;
  display: flex;
  justify-content: center; 
  align-items: center;
  margin-top:13px;
}
#or{
  margin-right: 2%;
  margin-left: 2%;
}
#or> h2{
  margin-block-end: 0.5em;
  margin-block-start: 0.5em;
}
.simChild{
  background-color: rgba(255, 255, 255, 0.7);
  width:42%;
  padding:5px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 90%;
}
.simChild ul{
  padding-inline-start: 0px;
  padding-bottom: 5px;
  padding-top:3px;
  background-color: rgba(255, 255, 255);
  border-radius: 10px;
  width:95%;
  font-size: 13px;
}
.simChildSubDiv{
  margin-bottom: 5px;
}
.select2{
  width:200px;
  display: inline-block;
}
.select2::after{
  padding-top: 3px;
}
.previousSims{
  cursor:pointer;
  list-style: none;
}
.previousSims:hover{
  background-color: rgb(206, 230, 238);
}
.simBtns{
  font-size: 14px;
  padding: 11px 22px;
}
h3{
  margin-block-start: 0.3em;
  margin-block-end: 0.3em;
}
h4{
  margin-block-start: 0.2em;
  margin-block-end: 0.2em;
}
h2, H3, h4{
  font-family: arial;
}
.accessBtn{
  background-color: #97c1ee;
}
.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.3s ease;
}

.component-fade-enter-from,
.component-fade-leave-to {
  opacity: 0;
}
#simCount{
    color: white;
    background-color: red;
    border-radius: 50%;
    position: absolute;
    top: 10px;
    left: 23px;
    height: 20px;
    width: 20px;
    font-weight: bold;
    display: flex;
    font-size: 14px;
    align-items: center;
    justify-content: center;
}
.error{
    color:red;
}
.custom-select12 {
  width: 130px;
  position: relative;
  display: block;
  margin: 1px 2px;
  /* border: 1px solid white; */
  z-index: 10;
  background-color: #0062cc;
  border-radius: 10px;
}
.schoolSelect{
  padding: 11.5px 13px !important;
  font-size: 14px !important;
}
.custom-select12:after {
  position: absolute;
  right: 0;
  top: 0;
  width: 37px;
  height: 100%;
  line-height: 45px !important;
  /* content: "∨"; */
  content: url("../assets/down-arrow.png");
  text-align: center;
  color: white;
  font-size: 14px;
  border-left: 1px solid white;
  padding-top: 6px;
  z-index: -1;
}
@media (max-width: 650px) {
#simPage{
  height: unset;
}
#simFlex{
  margin-top:54px;
  /* height: auto !important; */
  flex-direction: column;
  padding-bottom: 350px;
  position:sticky;
}
.simChild{
  width:80%;
}

}
@media (max-width: 550px)  {
#simFlex{
  margin-top:0px;
}
}
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
#simPage{
  height: unset;
}
}
@media (max-width: 650px) {
/* #simPage{
  height: auto;
} */
/* #contactImg {
    padding-top: 7px;
    position:absolute;
    right:32%;
}
#userPageItem {
    position: absolute;
    left: 32%;
    top: 86px;
}
#contactItem {
    position:unset;
    width: 100%;
    height: 58px;
}
#logoutItem{
  position:absolute;
  left:45%;
  top:86px;
} */
#navBar{
  position:inherit;
  margin:auto;
  right:0;
  left:0;
  top:83px;
  height: 60px;
}
}
@media (max-height: 720px) and (min-width: 650px) {
#simFlex{
  margin-top:unset !important;
}
#simFlex{
  height:77% !important;
}
}
/* @media (max-height: 670px) and (min-width: 650px) {
#simFlex{
  max-height:77% !important;
}
} */
/* @media (max-height: 560px) and (min-width: 650px) {
#simFlex{
  max-height:77% !important;
}
} */
@media (max-height: 620px) and (min-width: 650px) {
#simFlex{
  height:70% !important;
}
}
@media (max-width: 375px) {
#contactImg, #userImg, #logoutImg {
    width:35px;
}
#logoutItem {
    width: 35px;
    height: 35px;
}
}
@-moz-document url-prefix() {
.mozFix{
  padding-top:1px;
  padding-left:1px;
}
}
</style>

<template>
  <div class="userPage">
    <div class="profileItem2">
        <div class="profileHeader2">
            <h1>Bonjour {{username}}</h1>
        </div>
        <div id="profileBody2">
            <h2>Personnalisation des graphiques</h2>
            <div>
            <h4>Changer le json à afficher</h4>
                <label class="custom-select select2">
                <select class="select2 simBtns" v-model="selectedJson" @change="switchJson()">
                    <option v-for="json in availableJsons" v-bind:key="json" :value="json">{{json}}</option>
                </select>
                </label> 
            </div>
            <div>
                <h4>Uploader un nouveau json</h4>
                <input type="file" id="file" ref="jsonFile" name="jsonFile" @change="jsonUpload()">
                <button class="button simBtns" @click="addJson()">Ajouter le json</button>
            </div>
            <div>
                <p class="linkBtn" @click.prevent="getWidgetsFile()"><a href="">Télécharger widgets.json</a></p>
                <p class="linkBtn"><a href="/files/Edition des widgets.pdf" download>Documentation pour éditer le json</a></p>
                

            </div>
            <div v-if="$store.state.status =='Administrateur'">
              <h2>Admin</h2>
                  <div>
                    <h4>Accès au simulateur :</h4>
                      <button class="button simBtns" v-bind:class="{accessBtn: this.simAccess}" @click="setAccess(true)">On</button>
                      <button class="button simBtns" v-bind:class="{accessBtn: !this.simAccess}" @click="setAccess(false)">Off</button>
                  </div>
                  <div>
                    <h4>Nombre maximum de simulations par école :</h4>
                    <div>
                        <input class="rangeWidth" type="range" id="boundaryENVT" name="boundaryENVT"
                                min="0" max="100" v-model="boundaryENVT" @change="updateBoundary('ENVT', boundaryENVT)">
                         <p class="rangeLabel"><label for="boundaryENVT">ENVT: </label>{{boundaryENVT}}</p>
                    </div>
                    <div>
                        <input class="rangeWidth" type="range" id="boundaryAgreenium" name="boundaryAgreenium"
                                min="0" max="100" v-model="boundaryAgreenium" @change="updateBoundary('Agreenium', boundaryAgreenium)">
                         <p class="rangeLabel"><label for="boundaryAgreenium">Agreenium: </label>{{boundaryAgreenium}}</p>
                    </div>
                    <div>
                        <input class="rangeWidth" type="range" id="boundaryAutre" name="boundaryAutre"
                                min="0" max="100" v-model="boundaryAutre" @change="updateBoundary('Autre', boundaryAutre)">
                        <p class="rangeLabel"><label for="boundaryAutre">Autre: </label>{{boundaryAutre}}</p>
                    </div>
                  </div>
                  <div>
                    <h4>Ajouter un dataset :</h4>
                      <input type="file" id="file" ref="file" name="file" @change="datasetUpload()">
                      <button class="button simBtns" @click="addDataset()">Ajouter le dataset</button>
                      <button class="button simBtns" @click="deleteDatasets()">Effacer les datasets</button>
                  </div>
                  <div>
                    <h4>Journal de connexion moodle :</h4>
                      <button class="button simBtns" @click="getMoodleLog()">Télécharger le journal de connexion (.csv)</button>
                      <button class="button simBtns" @click="resetMoodleLog()">Remettre à 0 le journal de connexion</button>
                  </div>
                  <div>
                    <h4> Gestion des utilisateurs :</h4>
                      <button v-if="numberOfUsersToConfirm==1" class="button simBtns" @click="displayUserConfirmModal=true">Vous avez 1 nouvelle demande d'inscription.</button>
                      <button v-else-if="numberOfUsersToConfirm>1" class="button simBtns" @click="displayUserConfirmModal=true">Vous avez {{numberOfUsersToConfirm}} nouvelles demandes d'inscription.</button>
                      <button v-else class="button simBtns" @click="displayUserConfirmModal=true">Vous n'avez pas de nouvelles demandes d'inscription.</button>
                        <button class="button simBtns" @click="displayAccountAdminModal=true">Administration des comptes</button>
                  </div>
            </div>
            <div v-if="$store.state.status =='Enseignant' || $store.state.status =='Administrateur'">
                <div>
                    <h4>Journal de connexion moodle :</h4>
                      <button class="button simBtns" @click="getMoodleLog()">Télécharger le journal de connexion (.csv)</button>
                      <button class="button simBtns" @click="resetMoodleLog()">Remettre à 0 le journal de connexion</button>
                </div>
            </div>
        </div>
    </div>
    <userConfirmModal v-if="displayUserConfirmModal"/>
    <accountAdminModal v-if="displayAccountAdminModal"/>
  </div>
</template>

<script>
import axios from 'axios'
import config from '../../config.js'
import userConfirmModal from './modals/userConfirmModal.vue'
import accountAdminModal from './modals/accountAdminModal.vue'

export default {
    name:'userPage',
    components: {
        userConfirmModal,
        accountAdminModal
    },
    data(){
      return {
        displayUserConfirmModal:false,
        numberOfUsersToConfirm:"",
        file: '',
        simAccess: true,
        availableJsons:[],
        jsonFile:'',
        selectedJson:this.$store.state.jsonFile,
        username:"",
        displayAccountAdminModal:false,
        publicPath: process.env.BASE_URL,
        boundaryENVT:null,
        boundaryAgreenium:null,
        boundaryAutre:null
      }
    },
    mounted(){
        this.getJsonList()
        this.getnumberOfUsersToConfirm()
        this.getUserName()
        this.getBoundaries()
    },
    methods:{
        setAccess(bool){
         axios.get(config.serverUrl+"/set_access/?access="+bool)    
            .then(response => {
                this.simAccess = response.data.Access
            })
            .catch(e => {
                console.log(e)
            })
        },
        async addDataset(){
            let data = new FormData()
            data.append('dataset', this.file);
            await axios.post(config.serverUrl+"/upload_dataset/", 
            data,
            {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
            }).then(function(){
            console.log('SUCCESS!!')
            })
            .catch(e=>{
            console.log(e);
            })
        },
        datasetUpload(){
            this.file = this.$refs.file.files[0];
        },
        deleteDatasets(){
            axios.get(config.serverUrl+"/delete_all_datasets/")    
        },
        jsonUpload(){
            this.jsonFile = this.$refs.jsonFile.files[0];
        },
        async addJson(){
            var vm = this
            if(this.jsonFile ==''){
                vm.$store.commit("EDIT_FEEDBACK_MSG", "Vous devez choisir un fichier") 
            }else{
                let email = JSON.parse(localStorage.getItem('userSession'))
                let data = new FormData()
                data.append('newJson', this.jsonFile);
                await axios.post(config.serverUrl+"/upload_json/?email="+email.value[0][0], 
                data,
                {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
                    }).then(function(){
                        // console.log('SUCCESS!!')
                        vm.$store.commit("EDIT_FEEDBACK_MSG", "Votre fichier a bien été uploadé. Vous pouvez maintenant le sélectionner")                
                        vm.getJsonList()
                    })
                    .catch(function(){
                    // console.log(e);
                        vm.$store.commit("EDIT_FEEDBACK_MSG", "Un problème est survenu, vérifiez que votre fichier est bien au format json et/ou réessayez ultérieurement.")                
                    })
           }
            this.$store.commit("DISPLAY_FEEDBACK_MODAL")
        },
        getJsonList(){
            //Si l'utilisateur arrive depuis moodle, il n'a pas de userSession et le compte n'est pas validé, donc il ne peut pas utiliser les json personnalisés. 
            // s'il n'y a pas de userSession, on ne récupère pas les jsons depuis le fichier personnalisé de l'utilisateur -> moodleCheck est nul. Dans le cas contraire, moodleCheck prend la valeur de l'email utilisateur pour récupérer les jsons persos
            let moodleCheck = ""
            localStorage.getItem('userSession') == null? moodleCheck = "non": moodleCheck = JSON.parse(localStorage.getItem('userSession')).value[0][0]
            axios.get(config.serverUrl+"/get_json_list/?email="+moodleCheck)
                .then(response=>{
                // console.log('SUCCESS!!', response.data.jsonList)
                this.availableJsons = response.data.jsonList
                })
                .catch(e=>{
                console.log(e);
                })
        },
        getMoodleLog(){
            axios.get(config.serverUrl+"/get_connexion/")
            .then(response => {
                var blob = new Blob([response.data], { type: "application/octetstream" });
                var url = window.URL || window.webkitURL;
                var link = url.createObjectURL(blob);
                var a = document.createElement("a");
                a.setAttribute("download", "connexion.csv");
                a.setAttribute("href", link);
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }) 
      },
      resetMoodleLog(){
            axios.get(config.serverUrl+"/del_connexion/")    
      },
      async switchJson(){
            await this.$store.commit("SELECT_JSON_FILE", this.selectedJson)
            this.$parent.get_json_data()
            this.$store.commit("EDIT_FEEDBACK_MSG", "Votre choix a bien été pris en compte.")
            this.$store.commit("DISPLAY_FEEDBACK_MODAL")
      },
      getnumberOfUsersToConfirm(){
          if(this.$store.state.status=="Administrateur"){
            // let vm = this
            axios.get(config.serverUrl+"/numberOfUsersToConfirm/") 
            .then(response=>{
                this.numberOfUsersToConfirm = response.data.userInfo
            }).catch(e=>{
                console.log(e);
            })
          }
      },
      getUserName(){
        if(localStorage.getItem('userSession') == null){
            item = JSON.parse(localStorage.getItem('logDate'))
            this.username = item[0][1]+" "+item[0][2]
        }else{
            var item = JSON.parse(localStorage.getItem('userSession'))
            this.username = item.value[0][1]+" "+item.value[0][2]
        }
      },
      getWidgetsFile(){
            axios.get(config.serverUrl+"/get_widgets_file/", { responseType: "blob"})
            .then(response => {                
                var blob = new Blob([response.data], { type: "application/octetstream" });
                var url = window.URL || window.webkitURL;
                var link = url.createObjectURL(blob);
                var a = document.createElement("a");
                a.setAttribute("download", "widgets.json");
                a.setAttribute("href", link);
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }) 
      },
      getBoundaries(){
            axios.get(config.serverUrl+"/get_boundaries/")
            .then(response => {                
                console.log(response.data.Boundaries[0])
                this.boundaryENVT = response.data.Boundaries[0][1]
                this.boundaryAgreenium = response.data.Boundaries[1][1]
                this.boundaryAutre = response.data.Boundaries[2][1]
            })
            .catch(e=>{
                console.log(e);
            })
      },
      updateBoundary(institution, newLimit){
            axios.post(config.serverUrl+"/update_boundary/?institution="+institution+"&newLimit="+newLimit)
            .then(response => {                
                console.log(response.data)
            })
            .catch(e=>{
                console.log(e);
            })
      }
    }
}
</script>

<style>
.profileItem2{
    position: absolute;
    margin: auto;
    top: 33px;
    right: 0;
    bottom: 0;
    left: 0;
    width:90%;
    height: 71%;
    background-color: rgba(255, 255, 255, 0.7);
    overflow: auto;
}
.profileHeader2{
    margin: auto;
    width: 98%;
    border-style: none none solid none;
    border-width:  0 0 1px 0;
}
.profileHeader2> h1{
    margin-block-end: 0.5em;
}
#profileBody2{
    /* height: 442px;  */
    display: grid;
}
.linkBtn{
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
}
.rangeWidth{
    width:36%;
}
.rangeLabel{
    margin-block-start: 0;
}
@media (max-width: 650px) {
#profileBody2 {
    height: unset;
    margin-bottom: 10px;
}
.profileItem2 {
    position: sticky;
    margin-top: 0px !important;
    top: 150px;
    /* right: 0;
    bottom: unset;
    height: unset;
    overflow: unset; */
}
.userPage{
    padding-bottom:50px;
}
}
@media (max-height: 850px) {
.profileItem2 {
    /* position:unset; */
    margin-top: 69px;
    margin-bottom: 93px;
    height: inherit;
    padding-bottom: 14px;
}
}
</style>
<template>
    <div class="profileItem">
            <div class="error" v-if="incompleteForm">
                <p>Tous les champs doivent être complétés.</p>
            </div>
            <div class="error" v-if="errorsInForm">
                <p>Certains champs n'ont pas été correctement complétés.</p>
            </div>
            <div>
                <input class="registerInput2" type="text" placeholder="Adresse email " name="email" v-model="email" @change="emailValidate(), emailAlreadyTakenValidate()">
                <div class="error" v-if="emailError"><p>Email invalide</p></div>
                <div class="error" v-if="emailAlreadyTaken"><p>Cet email est déjà présent dans notre base de données.</p></div>
            </div>
            <div>
                <input class="registerInput2" type="text" placeholder="Prénom" name="firstName" v-model="firstName">
            </div>
            <div>
                <input class="registerInput2" type="text" placeholder="Nom" name="name" v-model="lastName">
            </div>
            <div>
                <input class="registerInput2" type="password"  placeholder="Mot de passe" name="password1" v-model="password1" @keyup="password1Validate()">
            </div>
            <div id="passwordError"><p>Le mot de passe doit contenir au moins 8 caractères, dont une majuscule, une minuscule, un caractère spécial et un nombre</p></div>
            <div>
                <input class="registerInput2" type="password" placeholder="Confirmation du mot de passe" name="password2" v-model="password2"   @keyup="password2Validate()">
                <div class="error" v-if="password2Error"><p>Vos mots de passe doivent être  identiques</p></div>
            </div>
            <div>
                <label  for="institution" class="custom-select2 select3">
                <select class="simBtns2" name="institution" v-model="institution">
                    <option value="Ecole" disabled >Ecole</option>
                    <option value="ENVT">ENVT</option>
                    <option value="Agreenium">Agreenium</option>
                    <option value="Autre">Autre</option>
                </select>
                </label>
            </div>
            <div>
                <label  for="occupation" class="custom-select2 select3">
                <select class="simBtns2" name="occupation" v-model="occupation">
                    <option value="Rôle" disabled >Rôle</option>
                    <option value="Etudiant">Etudiant</option>
                    <option value="Enseignant">Enseignant</option>
                    <option value="Autre">Autre</option>
                </select>
                </label>
            </div>
            <div v-if="occupation =='Etudiant'">
                <label  for="year" class="custom-select2 select3">
                <select class="simBtns2"  name="year" v-model="year">
                    <option value="Année d'étude" disabled >Année d'étude</option>
                    <option :value="'1'">1</option>
                    <option :value="'2'">2</option>
                    <option :value="'3'">3</option>
                    <option :value="'4'">4</option>
                    <option :value="'5'">5</option>
                    <option :value="'>5'">Supérieure à 5</option>
                </select>
                </label>
            </div>
            <div id="captchaInfo">
                <p>
                This site is protected by reCAPTCHA and the Google
                <a href="https://policies.google.com/privacy">Privacy Policy</a> and
                <a href="https://policies.google.com/terms">Terms of Service</a> apply.
                </p>
            </div>
            <div>
                <button class="sendBtn" @click="validate()">Envoyer</button>
            </div>
    </div>
</template>

<script>
import Vue from "vue"
import axios from 'axios'
import config from '../../config.js'
import { VueReCaptcha } from "vue-recaptcha-v3";
Vue.use(VueReCaptcha, { siteKey: '6LfCLHwbAAAAAInh6hQWrzj61Jm7GxF07StA9HPd' })

export default {
    name:'registerPage',
    components: {
    },
    data(){
      return {
        firstName:"",
        lastName:"",
        password1:"",
        password2:"",
        email:"",
        occupation:"Rôle",
        institution:"Ecole",
        year:"Année d'étude",
        firstNameError:false,
        nameError:false,
        password1Error:false,
        password2Error:false,
        emailError:false,
        emailAlreadyTaken:false,
        incompleteForm:false,
        errorsInForm:false
      }
    },
    mounted () {
        window.addEventListener('keyup', event => {
            if (event.key === 'Enter') { 
                this.validate()
            }
        })
    },
    methods:{
        emailAlreadyTakenValidate(){
            this.emailAlreadyTaken=false
            let vm=this
            axios.get(config.serverUrl+"/emailCheck/?emailToTest="+this.email,
                {  headers: { 'content-type': 'text/plain' }},
                ).then(response=>{
                    console.log(response)
                }).catch(function(){
                    vm.emailAlreadyTaken = true
                })
        },
        emailValidate(){
            this.emailError = false
            var regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
            regex.test(this.email) ? null : this.emailError = true
        },
        password1Validate(){
            this.password1Error = false
            var regex=/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&"'=+{}.,:;#/])[A-Za-z\d@$!%*?&"'=+{}.,:;#/]{8,}$/
            if(regex.test(this.password1)){
                    document.getElementById("passwordError").style.color = "black"
            } else{
              document.getElementById("passwordError").style.color = "red"
              this.password1Error = true
            }
        },
        password2Validate(){
            this.password2Error = false
            this.password1 == this.password2 ? null : this.password2Error = true
        },
        validate(){
                this.errorsInForm = false
                this.incompleteForm = false
            if(this.email=="" || this.password1 == "" || this.password2=="" || this.name=="" || this.firstName=="" || this.occupation=="Rôle" || this.institution=="Ecole" || this.occupation=="Etudiant" && this.year =="Année d'étude"){
                this.incompleteForm = true
            }else if(this.emailAlreadyTaken || this.password2Error || this.password1Error){
                this.errorsInForm = true
            } else {
                this.registerRequest()
            }
        },
        registerRequest(){
            this.occupation=="Enseignant"|| this.occupation=="Autre" ? this.year = null : null
            let vm=this
            let data={
                email: this.email,
                firstName:this.firstName,
                lastName:this.lastName,
                password:this.password1,
                role:this.occupation,
                institution:this.institution,
                year:this.year
            }
            this.$recaptcha("login").then((token)=>{
                data["g-recaptcha-response"] = token;

                axios.post(config.serverUrl+"/register/",
                        data,         
                        {  headers: { 'content-type': 'text/plain' }},
                    ).then(function(){
                        vm.$parent.register = false
                        vm.$store.commit("EDIT_FEEDBACK_MSG", "Votre inscription a bien été prise en compte. Vous pourrez accéder au simulateur lorsqu'elle aura été validée par un administrateur. Notez que ce compte sera valable 3 mois.")
                        vm.$store.commit("DISPLAY_FEEDBACK_MODAL")
                    }).catch(function(){
                        vm.$store.commit("EDIT_FEEDBACK_MSG", "Un problème est survenu, veuillez réessayer ultérieurement.")
                        vm.$store.commit("DISPLAY_FEEDBACK_MODAL")
                    })
            })
        },

    }
}
</script>

<style>

#captchaInfo{
    width:100%;
    display: flex;
    justify-content: center;
    font-size: 12px;
}
#passwordError, .error{
    width:100%;
    display: flex;
    justify-content: center;
    font-size: 14px;

}
#passwordError>p, #captchaInfo>p, .error>p{
    width:62%;
    margin-block-start: 0;
    margin-block-end: 0;
    font-size:12px;
}
.profileItem{
    background-color: rgba(255, 255, 255, 0.7);
    overflow: auto;
    width:90%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    /* flex-direction: column; */
    justify-content: space-around;
    padding-top:15px;
    padding-bottom:5px;
}
.profileItem>div{
    padding-top:10px;
    width:100%;
}
.error{
    color:red;
}
.registerInput2 {
    width: 54%;
    height: 40px;
    border-radius: 10px;
    border: 0px;
    color:white;
    box-shadow: #004da0 6px 6px 0px 0px inset;
    background-color: #0062cc !important;
    padding-left: 0.7em;
    outline: none;
    appearance: none;
    -webkit-appearance: none;
}
.select3 {
    width: 54% !important;
    height:40px;
    padding-left: 0.7em;
    box-shadow: #004da0 6px 6px 0px 0px inset;
    border: unset !important;
    display: inline-block;
}
.simBtns2 {
    font-size: 13.33px;
    font-family: arial;
    /* padding: unset !important; */
    width: -webkit-fill-available !important;
}
.registerInput2::placeholder {
    color: white;
    text-indent: 0.5em;
}
.grecaptcha-badge{
    visibility: hidden;
}
.sendBtn{
    border-radius: 5px;
    width: 40%;
    height: 40px;
    color:white;
    border: 0px;
    box-shadow: none;
    background-color: black !important;
    cursor: pointer;
    margin-bottom:10px;
    padding-left: 0.7em;
    outline: none;
}
.custom-select2 {
  width: 130px;
  position: relative;
  /* margin: 4px 2px; */
  border: 1px solid white;
  z-index: 10;
  background-color: #0062cc;
  border-radius: 10px;
}
.custom-select2:after {
  position: absolute;
  right: 0;
  top: 0;
  width: 37px;
  height: 100%;
  line-height: 38px !important;
  /* content: "∨"; */
  content: url("../assets/down-arrow.png");
  text-align: center;
  color: white;
  font-size: 22px;
  border-left: 1px solid white;
  padding-top: 8px;
  z-index: -1;
}
.registerInput2:-webkit-autofill,
.registerInput2:-webkit-autofill:hover,
.registerInput2:-webkit-autofill:focus,
.registerInput2:-webkit-autofill:active {
    -webkit-box-shadow: #004da0 6px 6px 0px 0px inset !important;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: white !important;
}
@media (max-width: 700px) {
/* .profileItem{
    padding-top:10px;
    padding-bottom:10px;
    flex-wrap: wrap;
    flex-direction: unset;
}
.profileItem>div{
    padding-top:10px;
    width:100%;
} */
.registerInput2, .select3 {
    width: 80% !important;
}
#passwordError>p, #captchaInfo>p, .error>p{
    width:75%;
}
}
/* @media (max-width: 500px) {
.sendBtn{
    width: 85%;
}
} */
</style>

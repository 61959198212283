<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
        <div class="modal-container">
            <div class="modal-footer">
                <button class="modal-default-button" @click="close()">Valider</button>
            </div>
            <div id="flexParams">
              <div>
                <table class="tble">
                  <thead>
                    <tr>
                      <th colspan="2">Initialisation de simulator manager</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item) in $store.state.simParams.sim_mgmt" :key="item.name">
                      <td>{{item.name}}</td>
                      <td><input class="paramInput" type="number" min="0" name="simd" :step="item.step" v-model="item.value" @change="commit(item, 'sim_mgmt')"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div>
                <table class="tble">
                  <thead>
                    <tr>
                      <th colspan="2">Prices</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item) in $store.state.simParams.prices" :key="item.name">
                      <td>{{item.name}}</td>
                      <td><input class="paramInput" type="number" min="0" name="simd" :step="item.step" v-model="item.value" @change="commit(item, 'prices')"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div>
                <table class="tble">
                  <thead>
                    <tr>
                      <th colspan="2">Med_prices</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item) in $store.state.simParams.med_prices" :key="item.name">
                      <td >{{item.name}}</td>
                      <td> <input class="paramInput" type="number" min="0" name="simd" :step="item.step" v-model="item.value" @change="commit(item, 'med_prices')"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
export default {
  name:"simParamModal",
  data(){
    return {
    }
  },
  methods:{
        close(){
            this.$parent.displayParamModal = false
        },
        commit(item, paramType){
          this.$store.commit('UPDATE_PARAM', {item, paramType})
        }
    }
} 
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 90%;
  height: 80%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  overflow:auto;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}
input[type="number"]{
  width:140px;
}
.paramInput{
  text-align:center;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
th, td{
  width:25%;
}
#flexParams{
  display: flex;
  justify-content: space-around;
}
@media (max-width: 1200px) {
#flexParams{
  display: block;
  margin:auto;
  padding-top:25px;
}
.tble{
    width: 44%;
    margin-left: auto;
    margin-right: auto;}
}
</style>
<template>
  <div id="app">
      <Chart v-if="$store.state.displayChart"/>
      <SimulationPage v-else-if="$store.state.logged"/>
      <entryPage v-else/>
      <feedbackModal v-if="this.$store.state.displayFeedbackModal"/>
      <accountInfoModal v-if="this.$store.state.displayAccountInfoModal"/>
      <!-- <div id="simLoading" v-if="$store.state.displaySimLoading">
        <div id="simHide" @click="toggle()">X</div>
        <p>{{$store.state.simState}}</p>
        <p> Votre simulation est en cours. Elle devrait être prête dans 2-3 minutes.</p>
				<img id="loadingImg" src="./assets/output_c5hJL0.gif">
      </div> -->
      <simLoadingModal v-if="this.$store.state.newSimAvailable" />

  </div>
</template>

<script>
import Chart from './components/chart.vue'
import entryPage from './components/loginPage.vue'
import SimulationPage from './components/simulationPage.vue'
import feedbackModal from './components/feedbackModal.vue'
import accountInfoModal from './components/modals/accountInfoModal.vue'
import simLoadingModal from './components/modals/simLoadingModal.vue'
import axios from 'axios'
import config from '../config.js'

export default {
  name: 'App',
  components: {
    Chart,
    entryPage,
    SimulationPage,
    feedbackModal,
    accountInfoModal,
    simLoadingModal,
  },
  data(){
     return {
     }
  },
  created(){
    //connexion moodle
    this.moodleLogin()
    this.sessionCheck()
    this.emailResetCheck()

    function getURLParameter(name) {
      return decodeURI((RegExp(name + '=' + '(.+?)(&|$)').exec(location.search)||[null])[1]);
    }
    function hideURLParams() {
    //Parameters to hide (ie ?success=value, ?error=value, etc)
    var hide = ['success','error'];
    for(var h in hide) {
      if(getURLParameter(h)) {
        history.replaceState(null, document.getElementsByTagName("title")[0].innerHTML, window.location.pathname);
      }
    }}

    //cacher les paramètres de l'url en connexion moodle
    window.onload = hideURLParams;


    window.addEventListener("beforeunload", async function () {
      //on récupère la date de connexion dans logdate, on calcule la différence et on convertit
      if(localStorage.getItem("logDate")!=null){
        var logDate = JSON.parse(localStorage.getItem('logDate'))
        console.log("logDate", logDate)
        var connexionTime = Date.now() - logDate[0][9];

        //Envoi à Python 
        axios.post(config.serverUrl+"/get_connexion_duration/",
          {
            firstName:logDate[0][1],
            lastName:logDate[0][2], 
            email: logDate[0][0],
            logDuration:connexionTime,                
          },         
          {  headers: { 'content-type': 'text/plain' }},
        ).then(response => {
          console.log(response)
        }).catch(e => {
          console.log(e)
        })
        localStorage.removeItem('logDate');
      }

    })
  },
  methods:{
    moodleLogin(){
      let token = new URLSearchParams(window.location.search).get('token')
      // S'il y a un name en paramètre, on va directement sur la page de simulation
        if(token != undefined){
              axios.get(config.serverUrl+"/moodleLogin/?token="+token)
              .then(response => {
                this.onLoggedIn(response)
              })
              .catch(e => {
                  console.log(e)
              })
        }
    },
    onLoggedIn(response){
      if(response.data.Done){	
          // this.$store.commit('SET_MOODLE_USERNAME', response.data.fullname)
          if(response.data.first_time_login){
            this.$store.commit("SET_MOODLE_SESSION", response.data)
            this.$store.commit("DISPLAY_ACCOUNT_INFO_MODAL")
          } else {
            let date = Date.now()
            response.data.userInfo[0].push(date)
            localStorage.setItem('logDate', JSON.stringify(response.data.userInfo))
          }                
          if(localStorage.getItem("runningSim")===null){
              const emptyArray = []
              localStorage.setItem("runningSim", JSON.stringify(emptyArray))
          }
          this.$store.commit('LOGGED')

      //erreurs
      } else if(response.data.token == "expired"){
          this.$store.commit('TOKEN_EXPIRED')
      } else {
          this.$store.commit('ACCESS_DENIED')
      }
    },
    sessionCheck(){
      var token = localStorage.getItem('userSession')
      if (token !== null) {
        var item = JSON.parse(token)
        if(Date.now() > item.expiry || item.expiry == "undefined"){
          localStorage.removeItem('userSession')
        } else{
          this.$store.commit('LOGGED')
          this.$store.commit('SET_STATUS', item.value[0][4])    
        } 
      }
    },
    emailResetCheck(){
        let token = new URLSearchParams(window.location.search).get('resetToken')
        if(token != undefined){
          console.log("token", token)
          this.$store.commit('DISPLAY_PWD_UPDATE', token)

              // axios.get(config.serverUrl+"/pwdUpdate/?token="+token)
              // .then(response => {
              //     console.log(response)
              // })
              // .catch(e => {
              //     console.log(e)
              // })
        }
    },
    async getPreviousScenario(scenario){
      await this.$store.commit("SELECT_PREVIOUS_SIM", scenario)
    },
  }
}
</script>

<style>
html, body { 
  margin: 0; 
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: black;
  height: 100%;
    /* background: url("./assets/fond-troupeau2.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position-y: 100%; */
}
</style>

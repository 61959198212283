import Vue from 'vue'
import Vuex from 'vuex'
import simParams from './modules/simParams.js'
import config from '../../config.js'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  modules:{
    simParams
  },
  state: {
    //chart
    indicators:{},
    weeklyActivities:{},
    milkTank:{},
    counts:{},
    result:{Flux:{Ani_Count:0}},
    menuActive:true,
    displayChart:false,

    //ErrorModals
    showMismatchModal:false,
    mismatchErrors:[],
    showNamingErrorModal: false,
    namingErrors:[],
    stackedErrorModal:false,
    stackedErrors:[],
    displayFeedbackModal:false,
    feedbackMsg:"",

    //header
    currentPeriod:0,
    selectedTime:"Période",
    maxWeek:"",
    maxMonth:"",
    maxYear:"",

    //login
    status: "",
    logged:false,
    tokenExpired:false,
    errorPwd:false,
    accessDenied:false,
    // moodleUserFullName:'',
    // moodleLogged:false,
    displayAccountInfoModal:false,
    displayPwdUpdate:false,
    resetToken:"",
    moodleEmail:"",
    moodleFirstName:"",
    moodleLastName:"",

    //selected sim
    scenario:"simul-S_T1_A1_HB0C0-20210512-181736-0x31",
    scenarioName:"",

    //json
    jsonData:"",
    jsonFile:"widgets.json",

    //simRunning
    newSimAvailableName:"",
    newSimAvailable:false,
    disableSim:true
  },
  mutations: {
    //chart
    async SETINDICATORS (state, res) {
      state.indicators = res
      Object.freeze(state.indicator)
      Object.freeze(state.tooltip)
    },
    SETWEEKLYINDICATORS (state, res) {
      state.weeklyActivities = res.activities,
      Object.freeze(state.weeklyActivities)
      state.milkTank = res.milkTank,
      state.counts = res.counts
    },
    TOGGLE_MENU(state){
      state.menuActive = !state.menuActive
    },
    RESPONSIVE_MODE_MENU(state){
      state.menuActive = false
    },
    BACK_TO_PARAMS(state){
      state.displayChart = false
    },

    //header
    SET_MAX_TIME (state, time) {
      state.maxWeek = time
      state.maxMonth = time/4
      state.maxYear = time/52
    },
    UPDATEINDICATORS (state, res) {
      state.result = res
      Object.freeze(state.result)
    },
    UPDATEPERIOD (state) {
      state.currentPeriod = 25
    },
    FORWARD (state, number) {
      state.currentPeriod += number
    },
    MOVE_TIME (state, number) {
      state.currentPeriod = number-1
    },
    UPDATE_SELECTED_TIME (state, selected) {
      state.selectedTime = selected
      state.currentPeriod = 0
    },

    //ErrorModals
    DISPLAY_ERROR_MODAL(state){
      state.showMismatchModal = true
    },
    HIDE_ERROR_MODAL(state){
      state.showMismatchModal = false
      state.mismatchErrors=[]
    },
    INCREMENT_MISMATCH_ERROR_TITLES(state, obj){
      state.mismatchErrors.push("Widget '"+obj.titleElt+"', chart n°"+obj.index)
    },
    DISPLAY_NAMING_ERROR_MODAL(state, obj){
      state.showNamingErrorModal = true
      state.namingErrors.push("Widget '"+obj.titleElt+"', chart n°"+obj.index)
    },
    HIDE_NAMING_ERROR_MODAL(state){
      state.showNamingErrorModal = false
    },
    DISPLAY_STACKED_ERROR_MODAL(state, chartInfo){
      state.stackedErrorModal = true
      state.stackedErrors.push("Widget '"+chartInfo.titleElt+"', chart n°"+chartInfo.index)
    },
    HIDE_STACKED_ERROR_MODAL(state){
      state.stackedErrorModal = false
    },
    DISPLAY_FEEDBACK_MODAL(state){
      state.displayFeedbackModal = true
    },
    HIDE_FEEDBACK_MODAL(state){
      state.displayFeedbackModal = false
    },
    EDIT_FEEDBACK_MSG(state, msg){
      state.feedbackMsg = msg
    },

    //login
    SET_STATUS(state, status){
      state.status = status
    },
    LOGGED(state){
      state.logged = !state.logged
    },
    TOKEN_EXPIRED(state){
      state.tokenExpired = true
    },
    ERROR_PWD(state){
      state.errorPwd = true
    },
    ACCESS_DENIED(state){
      state.accessDenied = true
    },
    // SET_MOODLE_USERNAME (state, username) {
    //   state.moodleUserFullName = username
    //   state.moodleLogged=true
    // },
    DISPLAY_ACCOUNT_INFO_MODAL(state){
      state.displayAccountInfoModal = !state.displayAccountInfoModal
    },
    SET_MOODLE_SESSION(state, data){
      state.moodleEmail = data.email
      state.moodleFirstName = data.firstName
      state.moodleLastName = data.lastName
    },
    DISPLAY_PWD_UPDATE(state, token){
      state.displayPwdUpdate = !state.displayPwdUpdate
      state.resetToken = token
    },

    //selected sim
    SELECT_PREVIOUS_SIM(state, selectedScenario){
      state.scenario = selectedScenario
      var stringSplit = selectedScenario.split("-")
      state.scenarioName= stringSplit[1]
      state.displayChart = true
    },

    //json
    GET_JSON_DATA(state, data){
      state.jsonData = data.jsonData
    },
    SELECT_JSON_FILE(state, newJson){
      state.jsonFile = newJson
    },

    //simRunning
    DISPLAY_SIM_AVAILABLE_MODAL(state, finishedSimName=""){
      state.newSimAvailableName = finishedSimName
      state.newSimAvailable = true
    },
    HIDE_SIM_AVAILABLE_MODAL(state){
      state.newSimAvailable = false
    },
    DISABLE_NEW_SIM(state, bool){
      state.disableSim = bool
    }
  },
  actions:{
    CHECK_SIM_STATE({commit}, id){
      return axios.get(config.serverUrl+"/check_sim_state/?simId="+id)
            .then(response => {
              var currentSim = JSON.parse(localStorage.getItem("runningSim"))
              var newState = false
              if(response.data.simState == "REVOKED"){
                return new Promise((resolve) => {
                  resolve({result:{simState:"REVOKED"}})
                })
              }else if(response.data.simState == "SUCCESS"){
                    var indexToRemove = currentSim.findIndex(function(sim){return sim.id ==  response.data.simId})
                    var finishedSimName = currentSim[indexToRemove].name
                    currentSim.splice(indexToRemove, 1);
                    localStorage.setItem("runningSim", JSON.stringify(currentSim))
                    return new Promise((resolve) => {
                        resolve({result:response.data, simName:finishedSimName})
                    })
              } else if(response.data.simState == "PENDING" || response.data.simState == "STARTED"){
                  //màj position dans la file (pending) et statut (quand on passe de pending à started)
                  for( var j = 0; j < currentSim.length; j++){ 
                    if (currentSim[j].id == response.data.simId && currentSim[j].state == "PENDING") { 
                      newState = true  
                      if (response.data.simState == "PENDING") {
                        currentSim[j].position = response.data.position
                      }else{
                        currentSim[j].position = 0
                        currentSim[j].state = "STARTED"
                      }
                      localStorage.setItem("runningSim", JSON.stringify(currentSim))
                    }
                  }

                  return new Promise((resolve) => {
                      resolve({result:response.data, newState:newState })
                  })
                }else{
                  // }else if(response.data.simState != "PENDING"){
                  commit("EDIT_FEEDBACK_MSG", "Un problème est survenu. Veuillez relancer une simulation ultérieurement.")
                  commit("DISPLAY_FEEDBACK_MODAL")
                } 
            })
            .catch(e=>{
                  console.log(e);
            })
    }
  }
})
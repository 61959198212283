<template>
  <div id="masse">
        <img class="contain" @click="switchImg()" src="../assets/planDeMasseDef.png"/>
        <div id="diseasesPicto" class="pdmPic">
            <img class="diseasesIcon" src="../assets/Croix_envt_cliniques.png"/>
        </div>
        <div id="diseasesData" class="pdmData">
            <p v-if="$store.state.result.Morbidity.CCMInc">Incidence Mcl: {{Number(Math.round($store.state.result.Morbidity.CCMInc*100+'e'+3)+'e-'+3)}}<span v-if="$store.state.selectedTime == 'Semaine'"> cas</span><span v-else>%</span></p><p v-else>Incidence Mcl: indisp.</p>
            <p v-if="$store.state.result.Morbidity.pVachesSup800">% Vc>800: {{Number(Math.round($store.state.result.Morbidity.pVachesSup800*100+'e'+3)+'e-'+3)}}<span v-if="$store.state.selectedTime == 'Semaine'"> cas</span><span v-else>%</span></p><p v-else>% Vc>800: indisp.</p>
            <p v-if="$store.state.result.DiseasesPrev.PrevLame != null">Boiteries: {{Number(Math.round($store.state.result.DiseasesPrev.PrevLame*100+'e'+3)+'e-'+3)}}<span>%</span></p><p v-else>Boiteries: 0</p>
            <p v-if="$store.state.result.DiseasesPrev.PrevMet != null">Métrite: {{Number(Math.round($store.state.result.DiseasesPrev.PrevMet*100+'e'+3)+'e-'+3)}}<span v-if="$store.state.selectedTime == 'Semaine'"> cas</span><span v-else>%</span></p><p v-else>Métrite: 0</p>
            <p v-if="$store.state.result.DiseasesPrev.PrevSck != null">Cétose: {{Number(Math.round($store.state.result.DiseasesPrev.PrevSck*100+'e'+3)+'e-'+3)}}<span v-if="$store.state.selectedTime == 'Semaine'"> cas</span><span v-else>%</span></p><p v-else>Cétose: 0</p>
        </div>
        <div id="cheptelPicto" class="pdmPic">
            <img class="cheptelIcon" src="../assets/IMG_VacheCompletFichier 48Simulateur.png"/>
        </div>
        <div id="cheptelData" class="pdmData">
            <p>Nombre de vaches</p>
            <div id="cheptelBox">
                <p>En lait: {{Number(Math.round($store.state.result.HerdCharacteristics.M_Hsize+'e'+1)+'e-'+1)}}</p>
                <p>Génisses: {{Number(Math.round($store.state.result.HerdCharacteristics.PreHerdSize+'e'+1)+'e-'+1)}}</p>
                <p>Taries: {{Number(Math.round($store.state.result.HerdCharacteristics.D_Hsize+'e'+1)+'e-'+1)}}</p>
            </div>
        </div>
        <div id="reproPicto" class="pdmPic">
            <img class="reproIcon" src="../assets/IMG_Reproduction.png"/>
        </div>
        <div id="reproData" class="pdmData">
            <div id="reproBox">
                <span>Taux de conception: <span v-if="$store.state.result.Reproduction.Rates">{{Number(Math.round($store.state.result.Reproduction.Rates.CRC+'e'+0)+'e-'+0)}}%</span><span v-else>indisp.</span></span>
                <span>IVV: {{Number(Math.round($store.state.result.Reproduction.CalvInterval+'e'+0)+'e-'+0) || "indisp."}}</span>
            </div>
        </div>
        <div id="milkPicto" class="pdmPic">
            <img class="milkIcon" src="../assets/Icon_LaitFichier 51Herd_Simul.png"/>
        </div>
        <div id="milkData" class="pdmData">
            <p>Prod/jour: <span v-if="$store.state.result.Flux.DMY">{{Number(Math.round($store.state.result.Flux.DMY+'e'+0)+'e-'+0)}}l</span><span v-else>indisp.</span></p>
            <p>BTSCC: {{Math.trunc((Number(Math.round($store.state.result.MilkTank.BTSCC+'e'+0)+'e-'+0))/1000)}}k</p>
        </div>
        <div id="animationMilk">
        </div>
        <div id="animationDisease">
        </div>
        <div id="animationCheptel">
        </div>
        <div id="animationRepro">
        </div>

        <div id="renouvellement">
            <p><img class="replacementRateIcon" src="../assets/renouvellement.png" /> <span v-if="$store.state.result.Flux.ReplacementRate">{{Number(Math.round($store.state.result.Flux.ReplacementRate+'e'+0)+'e-'+0)}}%</span><span v-else>indisp.</span></p>
        </div>
        <div id="reforme">
            <p>Réforme: <span v-if="$store.state.result.Flux.cullRate">{{Number(Math.round($store.state.result.Flux.cullRate+'e'+0)+'e-'+0)}}%</span><span v-else>indisp.</span></p>
        </div>
        <div id="reformeIcon"><img id="reformePic" src="../assets/reformeIcon.png"/></div>
        <div>
            <img class="robotIcon" src="../assets/robot de traite.svg"/>
        </div>
        <div>
            <img class="tankIcon" src="../assets/tank.png"/>
        </div>

        <div id="cheptelArrow"></div>
        <div id="milkArrow"></div>
        <div id="milkArrow2"></div>
        <div id="diseasesArrow"></div>
        <div id="reproArrow"></div>



  </div>
</template>

<script>
export default {
    name: 'planDeMasse',
    data(){
        return {
        }
    },
    methods: {
    }
}
</script>

<style>
#masse{
  position: relative;
  height: 100%;
}
.contain{
    width: 100%;
    height: 100%;
}
.pdmData{
    position:absolute;
    /* border-style: solid;
    border-width: 1px; */
    /* background-color:white; */
}
.pdmPic{
  position:absolute;
  background-size: cover;
  z-index: 1;
  width: 2.8%;
  height: 24px;
}
.robotIcon{
    background: white;
    width: 9.4%;
    position: absolute;
    top: 208px;
    right: 23%;
    height: 15%;
}
.pdmData p, .pdmData span, #renouvellement p, #reforme p{
    font-size:14px;
    margin-block-start: 0em;
    margin-block-end: 0em;
    text-align:center;
    text-indent:0%;
}
.tankIcon{
    width: 10.9%;
    position: absolute;
    bottom: 108px;
    right: 29.8%;
}
#renouvellement{
    position: absolute;
    bottom: 224px;
    left: -2%;
    width: 72px;
    transform: rotate(-90deg);
}
#reforme{
    position:absolute;
    top:73px;
    right:2%;
}
#reformeIcon{
    position:absolute;
    top: 72px;
    right: 118px;
    width: 3%;
}
#reformePic{
    width:86%;
}
.replacementRateIcon{
    vertical-align: middle;
    width: 22px;
}

/* DISEASE */
#diseasesPicto{
    bottom: 60px;
    right: 25.9%;
}
.diseasesIcon{
    width: 100%;
}
#animationDisease{
    position: absolute;
    bottom: 73px;
    right: 27%;
    width: 0.8%;
    height: 0.8%;
    animation: glowDiseases 1s infinite alternate;
    border-radius: 50%;
}
#diseasesData{
    background-color: rgba(255, 214, 214, 0.6);
    /* background-color: rgba(255, 255, 255, 0.6); */
    border-style: solid;
    border-width: 1px;
    border-color: #fe0303;
    bottom: 36px;
    right: 2.6%;
    width: 19%;
    height: 92px;
    padding: 2px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
/* #diseasesData>p{
    font-size:11px !important;
} */
#diseasesArrow{
    border-style: solid none none none;
    border-color: #fe0303;
    border-width: 2px;
    position: absolute;
    bottom: 38px;
    height: 35px;
    width: 4.6%;
    right: 22.4%;
}

/* CHEPTEL */
#cheptelPicto{
    top: 227px;
    left: 29.8%;
    background: transparent !important;
}
#cheptelData{
    background-color: rgba(183, 252, 169, 0.6);
        /* background-color: rgba(255, 255, 255, 0.6); */
    border-style: solid;
    border-color:#0af267;
    border-width: 1px;
    top: 69px;
    left: 6%;
    width: 29%;
    height: 47px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.cheptelIcon{
    width: 100%;

}
#animationCheptel{
    position: absolute;
    top: 233px;
    left: 30.7%;
    width: 1.3%;
    height: 1.3%;
    animation: glowCheptel 1s infinite alternate;
    border-radius: 50%;
}
#cheptelArrow{
    border-style: none solid none none;
    border-color: #0af267;
    border-width: 2px;
    position: absolute;
    top: 116px;
    height: 129px;
    width: 17px;
    left: 28.9%;
}

/* REPRO */
#reproPicto{
    bottom: 14px;
    left: 49.7%;
    background: transparent !important;
}
#reproData{
    /* background-color: rgba(255, 255, 255, 0.6); */
    background-color: rgba(250, 200, 244, 0.6);
    padding:0 10px;
    border-style: solid;
    border-color:#f11281;
    border-width: 1px;
    bottom: 10px;
    left: 3.6%;
    width: 32.6%;
    height: 32px;
    display:grid;
}
.reproIcon{
    width: 100%;
}
#animationRepro{
    position: absolute;
    bottom: 24px;
    left: 50.8%;
    width: 1.5%;
    height: 2.5%;
    animation: glowRepro 1s infinite alternate;
    border-radius: 50%;
}
#reproBox, #cheptelBox{
    display:flex;
    justify-content:space-between;
    align-items:center;
}
#reproArrow{
    border-style: solid none none none;
    border-color: #f11281;
    border-width: 2px;
    position: absolute;
    bottom: 6px;
    height: 21px;
    width: 11.2%;
    left: 39.2%;
}

/* MILK */
#milkPicto{
    right: 31.2%;
    top: 79px;
}
#animationMilk{
    position: absolute;
    right: 31.9%;
    top: 85px;
    width: 1.3%;
    height: 1.3%;
    animation: glowMilk 1s infinite alternate;
    border-radius: 50%;
}
#milkData{
    background-color: rgba(174, 244, 241, 0.6);
    /* background-color: rgba(255, 255, 255, 0.6); */
    /* border-color: #aef4f1; */
    border-color: #79e4e0;
    border-style: solid;
    border-width: 1px;
    right: 36%;
    top: 74px;
    width: 18%;
    height: 37px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.milkIcon{
    width: 100%;
}
#milkArrow{
    border-style: none none none solid;
    border-color: #79e4e0;
    border-width: 2px;
    position: absolute;
    top: 99px;
    height: 242px;
    width: 6.1%;
    right: 27.6%;
    transform: rotate(4deg);
}
#milkArrow2{
    border-style: none none none solid;
    border-color: #79e4e0;
    border-width: 2px;
    position: absolute;
    top: 88px;
    height: 116px;
    width: 6.1%;
    right: 23.9%;
    transform: rotate(-19deg);
}

@keyframes glowCheptel {
  from {
    box-shadow: 0 0 10px -10px #0af267;
  }
  to {
    box-shadow: 0 0 10px 10px #0af267;
  }
}
@keyframes glowDiseases {
  from {
    box-shadow: 0 0 10px -10px #fe030382;
  }
  to {
    box-shadow: 0 0 10px 10px #fe030382;
  }
}
@keyframes glowMilk {
  from {
    box-shadow: 0 0 10px -10px #aef4f1;
  }
  to {
    box-shadow: 0 0 10px 10px #aef4f1;
  }
}
@keyframes glowRepro {
  from {
    box-shadow: 0 0 10px -10px #fdcde5;
  }
  to {
    box-shadow: 0 0 10px 10px #fdcde5;
  }
}
@media (max-width: 1000px) {
.pdmData p, .pdmData span, #renouvellement p, #reforme p{
    font-size:1.55vw;
}
#cheptelArrow, #diseasesArrow, #reproArrow, #milkArrow, #milkArrow2 {
    display: none;
}
#reformeIcon{
    right: 16%;
}
.robotIcon {
    height: 14%;
}
.replacementRateIcon {
    width: 25%;
}
#renouvellement {
    left: -1.5%;
    bottom: 228px;
}
/* #reproPicto {
    left: 48.2%;
} */
#animationDisease {
    right: 26.8%;
}
#animationMilk {
    right: 32%;
}
#animationCheptel {
    left: 30.7%;
}
}
</style>

<template>
  <div>
    <Header/>
      <slot name="Menu">
              <!-- Index des charts scrollable + onglet update -->
        <div  class="menu" v-bind:class="{menu2: !this.$store.state.menuActive}">
            <div class="toggleBtn">
              <burger />
            </div>
            <img v-show="this.$store.state.menuActive" src="../assets/Logo complet.svg" width="200px">
            <p id="scenarioCode" v-show="this.$store.state.menuActive">Code scénario: {{$store.state.scenarioName}}</p>
            <ul v-show="this.$store.state.menuActive" id="obj" >
                <li>
                    <a href="#accueil" v-bind:class="{hoverHighlight: itemToHighligth=='accueil'}">Accueil</a>
                </li>
                <li v-for="(widget, index3) in widgets" :key="widgets[index3].titleElt">
                    <a :href="'#' + widgets[index3].titleElt" v-bind:class="{hoverHighlight: itemToHighligth==widgets[index3].titleElt}">{{ widgets[index3].titleElt }}</a>
                </li>
            </ul>
            <div v-show="this.$store.state.menuActive"> 
              <p>Vous utilisez le fichier "{{$store.state.jsonFile}}"</p>
            </div>
        </div>
      </slot>
      <slot name="Widgets">
              <!-- Affichage des widgets -->
        <div id ="chart">
          <namingErrorModal v-if="this.$store.state.showNamingErrorModal"/>
          <mismatchModal v-if="this.$store.state.showMismatchModal"/>
          <stackedErrorModal v-if="this.$store.state.stackedErrorModal"/>
          <div id="accueil" >
            <div class="widgetEltAccueil" v-bind:class="{toggleElt: !$store.state.menuActive}">
              <planDeMasse />
            </div>
          </div>                                

          <div v-for="(widget, index) in widgets" v-bind:key="widgets[index].titleElt" :id="widgets[index].titleElt">
            <div >
              <div class="widgetElt" v-bind:class="{toggleElt: !$store.state.menuActive}">
                 <div class="legendElt">
                    <img v-if="widgets[index].pic" :src="require('../assets/'+widgets[index].pic)" width="40px"/>
                    <p>{{widgets[index].titleElt}} </p> 
                    <img class="toolTipIcon" @click="toggleToolTip(index)" :src="require('../assets/tooltip-icon.png')"/>
                </div> 
                        <!-- <transition name="slide" mode="in-out"> -->

                <toolTipInfo v-if="tooltipDiv.includes(index)" :variablesToDefine=widgets[index] />
                        <!-- </transition> -->

                <div v-for="(chart, index2) in widgets[index].charts" v-bind:key="widgets[index].charts[index2].xAxis.categories[0]">
                    <highcharts v-if="widgets[index].charts[index2].series[0].data.length > 6" class="ch2" :options="widgets[index].charts[index2]" ref="chart"></highcharts>
                    <highcharts v-else-if="widgets[index].charts[index2].series[0].data.length < 3" class="ch1" :options="widgets[index].charts[index2]" ref="chart"></highcharts>
                    <highcharts v-else class="ch3" :options="widgets[index].charts[index2]" ref="chart"></highcharts>
                </div>
              </div>
            </div>
          </div>
        </div>
      </slot>
  </div>
</template>

<script>
import axios from 'axios'
import Highcharts from 'highcharts'
import exportingInit from 'highcharts/modules/exporting'
import HighchartsNoData from 'highcharts/modules/no-data-to-display'
import Header from './header.vue'
import mismatchModal from './modals/mismatchModal.vue'
import namingErrorModal from './modals/namingErrorModal.vue'
import stackedErrorModal from './modals/stackedErrorModal.vue'
import burger from './burger.vue'
import planDeMasse from './planDeMasse.vue'
import toolTipInfo from './toolTip.vue'
import config from '../../config.js'
 
exportingInit(Highcharts)
HighchartsNoData(Highcharts);

//Composant qui édite des widgets en fonction des consignes d'un json et des données récupérées en back
export default {
  name: 'chart',
  components: {
    Header,
    mismatchModal,
    burger,
    namingErrorModal,
    stackedErrorModal,
    toolTipInfo,
    planDeMasse
  },
  data(){
     return {
      widgets:[],
      done:false,
      doc:'',
      jsonWidgetDoc:this.$store.state.jsonData.json,
      jsonTooltip:this.$store.state.jsonData.tooltip,
      definitions:this.$store.state.jsonData.Definitions,
      file:'',
      showNamingErrorModal:false,
      tooltipDiv:[],
      itemToHighligth:"accueil",
     }
  },
  async mounted(){
    await this.getData()
    window.onresize = () => {this.WindowWidthMgmt()}
    window.addEventListener('scroll', this.handleScroll)
  }, 
  created() {
    this.WindowWidthMgmt()
    this.$isLoading(true)
  },
  beforeUnmount () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods:{
    // @vuese
    // Récupère les données d'une simulation
    // @arg La simulation choisie est passée en paramètre de la requête API
    async getData(){
      var institution
      if(localStorage.getItem('userSession') == null){
          institution = JSON.parse(localStorage.getItem('logDate'))[0][8]
      }else{
          var item = JSON.parse(localStorage.getItem('userSession'))
          institution = item.value[0][7]
      }
      axios.get(config.serverUrl+"/get_sim/?filename="+this.$store.state.scenario+"&institution="+institution)    
                  .then(async response => {
                     console.log("resp", response.data)
                      var totalWeek = response.data.metadata.sim_duration;
                      this.$store.commit('SET_MAX_TIME', totalWeek)
                      await this.$store.commit('SETINDICATORS', response.data.indicators)
                      //console.log("indic", response.data.indicators)
                      this.$store.commit('SETWEEKLYINDICATORS', response.data.sim_outputs)
                      // console.log("weekIndic", response.data.sim_outputs)
                      await this.getCurrentIndicators()
                      await this.chartEdit(this.jsonWidgetDoc)
                  })
                  .catch(e => {
                      console.log(e)
                  })
    },
    // @vuese
    // Edition des widgets génériques
    // @arg Utilise le json qui contient les noms, données, labels et logos éventuels
    async chartEdit(json){
      //this.widgets = objet qui contient tous les charts et les widgets pour l'affichage
      this.widgets = []
      for (let i = 0; i < json.length; i++){
          //widgetElt = objet qui contien les infos pour chaque widget
          var widgetElt = {                
            titleElt:"",
            pic:false,
            numberOfVariables:0,
            charts:[],
            defs:[],
          }
          this.widgets.push(widgetElt)
          // implémentation titre et pic de chaque widget
          this.widgets[i].titleElt = json[i].nom_widget
          this.widgets[i].pic = json[i].pic
          //implémentation des paramètres des charts pour le widget i
          for(let z = 0; z < json[i].charts.length; z++){
                var chartOptions = {
                chart: {
                    type: 'bar',
                    backgroundColor:'transparent',
                            animation: true,

                },
                noData:{
                    style:{
                        color:"red"
                    }    
                },
                lang: {
                   noData: "Pas de données disponibles pour cette temporalité."
                  },
                title:{
                  text:null,
                  style:{fontSize:'20px', fontWeight: 'bold'}
                  },
                xAxis: {
                    categories: ["ok"],
                    labels: {
                      useHTML: true,
                      style: {fontSize:'16px'}
                    },
                    title: {
                        text: null
                    }
                },
                plotOptions: {
                    series: {
                      stacking:undefined,
                      pointWidth : 20,
                      // animation: {
                      //   duration: 2000
                      // }
                    }
                },
                tooltip: {                     
                    followPointer:true,
                    formatter: function () {
                        return this.x +': ' + this.y
                    }
                },
                legend: {
                  enabled: false,
                  reversed: true,
                },
                yAxis: {
                    title: {
                        text: '',
                        style: {
                            fontSize:'16px'
                        }
                    },
                    labels: {
                      style: {
                        fontSize:'14px'
                      }
                    },
                },
                series: [
                    {
                        data: []
                    }
                ],
                } 
                this.widgets[i].charts.push(chartOptions)
                //implémentation unité, titre chart, étiquettes données selon le type de chart et définition
                json[i].charts[z].chart_type != undefined? this.widgets[i].charts[z].chart.type = json[i].charts[z].chart_type : null
                this.widgets[i].charts[z].xAxis.categories = json[i].charts[z].chart_text || []
                this.widgets[i].charts[z].yAxis.title.text = json[i].charts[z].unit
                this.widgets[i].charts[z].title.text = json[i].charts[z].chart_title
                this.widgets[i].defs.push([])
                //implémentation des données et (étiquettes si elles n'ont pas été renseignées)
                var path
                var vm = this

                //cas diagramme empilé
                if (json[i].charts[z].chart_stacked == true) {
                  this.widgets[i].charts[z].legend.enabled = true
                  this.widgets[i].charts[z].plotOptions.series.stacking = "normal"
                  this.stackValidation(z,this.widgets[i].titleElt, json[i].charts[z])
                  for (let j = 0; j < json[i].charts[z].chart_value.length; j++){
                    j <json[i].charts[z].chart_value.length-1 ? this.widgets[i].charts[z].series.push({data:[]}) : null
                    for (let h = 0; h < json[i].charts[z].chart_value[j].length; h++){
                        if (json[i].charts[z].chart_value[j][h] == 0) {this.widgets[i].charts[z].series[j].data.push(0)} else {
                        path = json[i].charts[z].chart_value[j][h].split('.')
                        this.namingError(z, path, this.widgets[i].titleElt)
                        if (path.length == 3){
                          this.widgets[i].charts[z].series[j].data.push(this.round(this.$store.state.result[path[0]][path[1]][path[2]]))
                          this.widgets[i].charts[z].series[j].name = path[2]
                        } else {
                          this.widgets[i].charts[z].series[j].data.push(this.round(this.$store.state.result[path[0]][path[1]]))
                          this.widgets[i].charts[z].series[j].name = path[1]
                        } 
                        //definitions
                        // this.widgets[i].charts[z].def[z].push(json[i].charts[z].chart_value[j][h])
                        this.widgets[i].defs[z].push(path[path.length-1])
                      }
                    }
                  } 
                  this.widgets[i].charts[z].legend.labelFormatter = function () { return "<div>"+vm.jsonTooltip[this.name][0]+"</div>"}
                  this.widgets[i].charts[z].tooltip.formatter = function () { return '<div styme="backgroundColor: "rgba(255,255,255,1)">'+vm.jsonTooltip[this.series.name][0] +': ' + this.y+'</div>'}
                //cas diagramme non empilé
                }else{
                  for (let j = 0; j < json[i].charts[z].chart_value.length; j++){
                    path = json[i].charts[z].chart_value[j].split('.') 
                    path!= undefined? this.namingError(z, path, this.widgets[i].titleElt) : null
                    if (path.length == 1 && json[i].charts[z].chart_value.length == 1 ) {
                    //chart_value correspond à une catégorie
                        this.widgets[i].charts[z].series[0].data = Object.values(this.$store.state.result[path[0]]).map(x => this.round(x))
                        json[i].charts[z].chart_text == undefined ? Object.keys(this.$store.state.result[path[0]]).map(x => this.widgets[i].charts[z].xAxis.categories.push(this.jsonTooltip[x][0])) : null
                        //definitions
                        this.widgets[i].defs[z] = Object.keys(this.$store.state.result[path[0]])
                    } else if (path.length == 2  && json[i].charts[z].chart_value.length == 1 ) {
                        if ( typeof this.$store.state.result[path[0]][path[1]] ==='object' && this.$store.state.result[path[0]][path[1]] != null) {
                        // correspond à une sous catégorie
                        this.widgets[i].charts[z].series[0].data = Object.values(this.$store.state.result[path[0]][path[1]]).map(x => this.round(x))
                        json[i].charts[z].chart_text == undefined ? Object.keys(this.$store.state.result[path[0]][path[1]]).map(x => this.widgets[i].charts[z].xAxis.categories.push(this.jsonTooltip[x][0])) : null
                        //definitions
                        this.widgets[i].defs[z] = Object.keys(this.$store.state.result[path[0]][path[1]])
                        } else {
                        //correspond à une une seule variable
                        this.widgets[i].charts[z].series[0].data[j] = this.round(this.$store.state.result[path[0]][path[1]])
                        json[i].charts[z].chart_text == undefined && this.$store.state.result[path[0]][path[1]] != undefined ? this.widgets[i].charts[z].xAxis.categories.push(this.jsonTooltip[path[1]][0]) : null
                        //definitions
                        this.widgets[i].defs[z] = [path[1]]
                        }
                        
                    } else if (path.length == 2  && json[i].charts[z].chart_value.length > 1 ) {
                    // correspond à une variable
                        this.widgets[i].charts[z].series[0].data[j] = this.round(this.$store.state.result[path[0]][path[1]])
                        json[i].charts[z].chart_text == undefined ? this.widgets[i].charts[z].xAxis.categories.push(this.jsonTooltip[path[1]][0]) : null
                        //definitions
                        this.widgets[i].defs[z].push(path[1])
                    } else if (path.length == 3) {
                    //correspond à une variable
                        this.widgets[i].charts[z].series[0].data[j] = this.round(this.$store.state.result[path[0]][path[1]][path[2]])
                        json[i].charts[z].chart_text == undefined ? this.widgets[i].charts[z].xAxis.categories.push(this.jsonTooltip[path[2]][0]) : null
                        //definitions
                        this.widgets[i].defs[z].push(path[2])
                    }
                  }
                  this.widgets[i].numberOfVariables += this.widgets[i].charts[z].series[0].data.length
                  this.mismatchError(this.widgets[i].charts[z], this.widgets[i].titleElt, z)
                }
          }
      }
      if (this.$store.state.mismatchErrors.length != 0 && this.$store.state.showNamingErrorModal != true) {
        this.$store.commit('DISPLAY_ERROR_MODAL')
      }
      //console.log("len", this.widgets)
      //         console.log("result", this.$store.state.result)

      this.$isLoading(false)
    },
    round(value){
      let num =  Number(Math.round(value+'e'+3)+'e-'+3);
      if(!isNaN(num)){ 
        return num
      } else{return undefined}
    },
    mismatchError(chart, widgetTitle, index){
      if (chart.xAxis.categories.length != chart.series[0].data.length && this.$store.state.selectedTime == "Période") {
        var obj = {titleElt:widgetTitle, index:index+1 }
        this.$store.commit('INCREMENT_MISMATCH_ERROR_TITLES', obj)
      }
    },
    namingError(chartIndex, path, widgetTitle){
      var sourceValue = this.$store.state.result[path[0]]
      if (path.length >0) {
        for (var w = 1; w < path.length; w++) {
            if(sourceValue == undefined && this.$store.state.selectedTime == "Période"){
              var obj = {titleElt:widgetTitle, index:chartIndex+1}
              this.$store.commit('DISPLAY_NAMING_ERROR_MODAL', obj)
            }
            sourceValue = sourceValue[path[w]]
        }   
      }
      if(sourceValue == undefined && this.$store.state.selectedTime == "Période"){
        var obj2 = {titleElt:widgetTitle, index:chartIndex+1}
        this.$store.commit('DISPLAY_NAMING_ERROR_MODAL', obj2)
      }
    },
    stackValidation(chartIndex, widgetTitle, chart){
      for (let index4 = 0; index4 < chart.chart_value.length; index4++) {
        if(chart.chart_value[index4].length != chart.chart_text.length){
          var obj3 = {titleElt:widgetTitle, index:chartIndex+1}
          this.$store.commit('DISPLAY_STACKED_ERROR_MODAL', obj3)
        }
      }
    },
    fileUpload(){
      this.file = this.$refs.file.files[0];
    },
    // @vuese
    // Mise à jour des données à afficher dans les widgets selon la date choisie
    // @arg La date et les données choisies sont récupérées dans le store
    async getCurrentIndicators(){
      // console.log("indicators", this.$store.state.indicators)
      let index = this.$store.state.currentPeriod;
      let result;
      switch (this.$store.state.selectedTime) {
        case "Période":
          result={
            DiseasesPrev: {
              PrevSck:this.$store.state.indicators.DiseasesPrevPeriod.PrevSck[index],
              PrevRpl:this.$store.state.indicators.DiseasesPrevPeriod.PrevRpl[index],
              PrevMet:this.$store.state.indicators.DiseasesPrevPeriod.PrevMet[index],
              PrevPvd:this.$store.state.indicators.DiseasesPrevPeriod.PrevPvd[index],
              PrevLad:this.$store.state.indicators.DiseasesPrevPeriod.PrevLad[index],
              PrevHca:this.$store.state.indicators.DiseasesPrevPeriod.PrevHca[index],
              PrevSce:this.$store.state.indicators.DiseasesPrevPeriod.PrevSce[index],
              PrevLame:this.$store.state.indicators.DiseasesPrevPeriod.PrevLame[index],
            },
            Morbidity:{
              mastitis_count:this.$store.state.indicators.CellCMPeriod.mastitis_count[index],
              CCMInc:this.$store.state.indicators.CellCMPeriod.CCMInc[index],
              CMInc:this.$store.state.indicators.CellCMPeriod.CMInc[index],
              CMperCow:this.$store.state.indicators.CellCMPeriod.CMperCow[index],
              pVachesSup800:this.$store.state.indicators.CellCMPeriod.pVachesSup800[index],
              pVachesInf300:this.$store.state.indicators.CellCMPeriod.pVachesInf300[index],
            },
            Reproduction: {
              Rates:{
                FSCRL:this.$store.state.indicators.ReproPerfPeriod.FSCRL[index],
                CRC:this.$store.state.indicators.ReproPerfPeriod.CRC[index],
                PcVCs3IAL:this.$store.state.indicators.ReproPerfPeriod.PcVCs3IAL[index],
              },
              Ages:{
                HeifAgeCalv1:this.$store.state.indicators.ReproPerfPeriod.HeifAgeCalv1[index],
                HeifAgeAIF:this.$store.state.indicators.ReproPerfPeriod.HeifAgeAIF[index],
              },
              CalvInterval:this.$store.state.indicators.ReproPerfPeriod.CalvInterval[index],
            },					
            Economy:{
              Incomes:{
                income_Milk: this.$store.state.indicators.EconomicBookletPeriod.income_Milk[index],
                income_MeatCull: this.$store.state.indicators.EconomicBookletPeriod.income_MeatCull[index],
                income_MeatCalfs: this.$store.state.indicators.EconomicBookletPeriod.income_MeatCalfs[index],
                income_heifersRTC: this.$store.state.indicators.EconomicBookletPeriod.income_heifersRTC[index],
              },
              Expenses:{
                expenses_Straw: this.$store.state.indicators.EconomicBookletPeriod.expenses_Straw[index],
                expenses_Food: this.$store.state.indicators.EconomicBookletPeriod.expenses_Food[index],
                expenses_Meds: this.$store.state.indicators.EconomicBookletPeriod.expenses_Meds[index],
                expenses_Vet: this.$store.state.indicators.EconomicBookletPeriod.expenses_Vet[index],
                expenses_Insem: this.$store.state.indicators.EconomicBookletPeriod.expenses_Insem[index],
              }
            },
            HerdCharacteristics:{
              M_Hsize: this.$store.state.indicators.HerdCharacteristicsPeriod.M_Hsize[index],
              D_Hsize: this.$store.state.indicators.HerdCharacteristicsPeriod.D_Hsize[index],
              PreHerdSize: this.$store.state.indicators.HerdCharacteristicsPeriod.preHerdSize[index],
              Nbirth_Male: this.$store.state.indicators.HerdCharacteristicsPeriod.Nbirth_Male[index],
              Nbirth_Female: this.$store.state.indicators.HerdCharacteristicsPeriod.Nbirth_Female[index],
            },
            MilkTank:{
              BTSCC: this.$store.state.indicators.CellCMPeriod.BTSCC[index],
              BT_TP: this.$store.state.indicators.CellCMPeriod.BT_TP[index],
              BT_TB: this.$store.state.indicators.CellCMPeriod.BT_TB[index],
              Out_Mi_250: this.$store.state.indicators.ProductionOutputsPeriod.out_Mi_250[index]/1000,
              Out_Mi_300: this.$store.state.indicators.ProductionOutputsPeriod.out_Mi_300[index]/1000,
              Out_Mi_400: this.$store.state.indicators.ProductionOutputsPeriod.out_Mi_400[index]/1000,
              Out_Mi_s400: this.$store.state.indicators.ProductionOutputsPeriod.out_Mi_s400[index]/1000,
              milkTank: this.$store.state.indicators.ProductionOutputsPeriod.out_Mi_250[index]/1000 +
                this.$store.state.indicators.ProductionOutputsPeriod.out_Mi_300[index]/1000 +
                this.$store.state.indicators.ProductionOutputsPeriod.out_Mi_400[index]/1000 +
                this.$store.state.indicators.ProductionOutputsPeriod.out_Mi_s400[index]/1000
            },
            FoodInputs:{
              Ens_Mais_kg: this.$store.state.indicators.InputParametersPeriod.Ens_Mais_kg[index]/1000,
              Foin_kg: this.$store.state.indicators.InputParametersPeriod.Foin_kg[index]/1000,
              Trx_Soja_kg: this.$store.state.indicators.InputParametersPeriod.Trx_Soja_kg[index]/1000,
              Cc_Ble_kg: this.$store.state.indicators.InputParametersPeriod.Cc_Ble_kg[index]/1000,
              AMV_kg: this.$store.state.indicators.InputParametersPeriod.AMV_kg[index],
              inp_Cfood_MILK: this.$store.state.indicators.InputParametersPeriod.inp_Cfood_MILK[index],
              inp_Cfood_CC: this.$store.state.indicators.InputParametersPeriod.inp_Cfood_CC[index],
            },
            Outputs:{
              Counts:{
                out_Ani_SH:this.$store.state.indicators.ProductionOutputsPeriod.out_Ani_SH[index],
                out_Ani_SC:this.$store.state.indicators.ProductionOutputsPeriod.out_Ani_SC[index],
                out_Ani_C:this.$store.state.indicators.HerdCharacteristicsPeriod.Nb_CulledCows[index],
              },
              Rates:{
                morRate:this.$store.state.indicators.TechnicalRatesPeriod.morRate[index],
                calfMorRate:this.$store.state.indicators.TechnicalRatesPeriod.calfMorRate[index],
                heifMorRateM1:this.$store.state.indicators.TechnicalRatesPeriod.heifMorRateM1[index],
                heifMorRateM6:this.$store.state.indicators.TechnicalRatesPeriod.heifMorRateM6[index],
              }
            },
            Pharmacy:{
              DCD:{
                DCD_heifers:this.$store.state.indicators.atbExposurePeriod.DCD_heifers[index],
                DCD_Cows:this.$store.state.indicators.atbExposurePeriod.DCD_Cows[index],
                DCD_imm_inLactCows:this.$store.state.indicators.atbExposurePeriod.DCD_imm_inLactCows[index],
              },
              ALEA:{
                ALEA_calves:this.$store.state.indicators.atbExposurePeriod.ALEA_calves[index],
                ALEA_heifers:this.$store.state.indicators.atbExposurePeriod.ALEA_heifers[index],
                ALEA_driedCows:this.$store.state.indicators.atbExposurePeriod.ALEA_driedCows[index],
              },
              Treats:{
                treatOnce:this.$store.state.indicators.atbExposurePeriod.treatOnce[index],
                h_treatOnce:this.$store.state.indicators.atbExposurePeriod.h_treatOnce[index],
              }
            },
            Flux:{
              DMY:this.$store.state.indicators.MilkProdPeriod.DMY[index],
              cullRate:this.$store.state.indicators.TechnicalRatesPeriod.cullRate[index],
              ReplacementRate:this.$store.state.indicators.TechnicalRatesPeriod.replacementRate[index],
              CcexpensePerMilk: this.$store.state.indicators.EconomicBookletPeriod.CcexpensePerMilk[index],
              Ani_Count: Math.round(this.$store.state.indicators.HerdCharacteristicsPeriod.M_Hsize[index]
                    +this.$store.state.indicators.HerdCharacteristicsPeriod.D_Hsize[index]),
            
            }
          }
          this.$store.commit('UPDATEINDICATORS', result)
          break;
        case "Année":
          result={
            DiseasesPrev: {
              PrevSck:this.$store.state.indicators.DiseasesPrevYear.PrevSck[index],
              PrevRpl:this.$store.state.indicators.DiseasesPrevYear.PrevRpl[index],
              PrevMet:this.$store.state.indicators.DiseasesPrevYear.PrevMet[index],
              PrevPvd:this.$store.state.indicators.DiseasesPrevYear.PrevPvd[index],
              PrevLad:this.$store.state.indicators.DiseasesPrevYear.PrevLad[index],
              PrevHca:this.$store.state.indicators.DiseasesPrevYear.PrevHca[index],
              PrevSce:this.$store.state.indicators.DiseasesPrevYear.PrevSce[index],
              PrevLame:this.$store.state.indicators.LamnessIndicatorsperYear.wtmed_PrevLameness[index],
            },
            Morbidity:{
              mastitis_count:this.$store.state.indicators.CellCMYear.mastitis_count[index],
              CCMInc:this.$store.state.indicators.CellCMYear.CCMInc[index],
              CMInc:this.$store.state.indicators.CellCMYear.CMInc[index],
              CMperCow:this.$store.state.indicators.CellCMYear.CMperCow[index],
              pVachesSup800:this.$store.state.indicators.CellCMYear.pVachesSup800[index],
              pVachesInf300:this.$store.state.indicators.CellCMYear.pVachesInf300[index],
            },
            Reproduction: {
              Rates:{
                FSCRL:this.$store.state.indicators.ReproPerfYear.FSCRL[index],
                CRC:this.$store.state.indicators.ReproPerfYear.CRC[index],
                PcVCs3IAL:this.$store.state.indicators.ReproPerfYear.PcVCs3IAL[index],
              },
              Ages:{
                HeifAgeCalv1:this.$store.state.indicators.ReproPerfYear.HeifAgeCalv1[index],
                HeifAgeAIF:this.$store.state.indicators.ReproPerfYear.HeifAgeAIF[index],
              },
              CalvInterval:this.$store.state.indicators.ReproPerfYear.CalvInterval[index],
            },
            Economy:{
              Incomes:{
                income_Milk: this.$store.state.indicators.EconomicBookletYear.income_Milk[index],
                income_MeatCull: this.$store.state.indicators.EconomicBookletYear.income_MeatCull[index],
                income_MeatCalfs: this.$store.state.indicators.EconomicBookletYear.income_MeatCalfs[index],
                income_heifersRTC: this.$store.state.indicators.EconomicBookletYear.income_heifersRTC[index],
              },
              Expenses:{
                expenses_Straw: this.$store.state.indicators.EconomicBookletYear.expenses_Straw[index],
                expenses_Food: this.$store.state.indicators.EconomicBookletYear.expenses_Food[index],
                expenses_Meds: this.$store.state.indicators.EconomicBookletYear.expenses_Meds[index],
                expenses_Vet: this.$store.state.indicators.EconomicBookletYear.expenses_Vet[index],
                expenses_Insem: this.$store.state.indicators.EconomicBookletYear.expenses_Insem[index],
                // CcexpensePerMilk: this.$store.state.indicators.EconomicBookletYear.CcexpensePerMilk[index],
                // milk_shortfall: this.$store.state.indicators.EconomicBookletYear.milk_shortfall[index],
              }
            },
            HerdCharacteristics:{
              M_Hsize: this.$store.state.indicators.HerdCharacteristicsYear.M_Hsize[index],
              D_Hsize: this.$store.state.indicators.HerdCharacteristicsYear.D_Hsize[index],
              PreHerdSize: this.$store.state.indicators.HerdCharacteristicsYear.preHerdSize[index],
              Nbirth_Male: this.$store.state.indicators.HerdCharacteristicsYear.Nbirth_Male[index],
              Nbirth_Female: this.$store.state.indicators.HerdCharacteristicsYear.Nbirth_Female[index],
            },
            MilkTank:{
              BTSCC: this.$store.state.indicators.CellCMYear.BTSCC[index],
              BT_TP: this.$store.state.indicators.CellCMYear.BT_TP[index],
              BT_TB: this.$store.state.indicators.CellCMYear.BT_TB[index],
              Out_Mi_250: this.$store.state.indicators.ProductionOutputsYear.out_Mi_250[index]/1000,
              Out_Mi_300: this.$store.state.indicators.ProductionOutputsYear.out_Mi_300[index]/1000,
              Out_Mi_400: this.$store.state.indicators.ProductionOutputsYear.out_Mi_400[index]/1000,
              Out_Mi_s400: this.$store.state.indicators.ProductionOutputsYear.out_Mi_s400[index]/1000,
              milkTank: this.$store.state.indicators.ProductionOutputsYear.out_Mi_250[index]/1000 +
                this.$store.state.indicators.ProductionOutputsYear.out_Mi_300[index]/1000 +
                this.$store.state.indicators.ProductionOutputsYear.out_Mi_400[index]/1000 +
                this.$store.state.indicators.ProductionOutputsYear.out_Mi_s400[index]/1000
            },
            FoodInputs:{
              Ens_Mais_kg: this.$store.state.indicators.InputParametersYear.Ens_Mais_kg[index]/1000,
              Foin_kg: this.$store.state.indicators.InputParametersYear.Foin_kg[index]/1000,
              Trx_Soja_kg: this.$store.state.indicators.InputParametersYear.Trx_Soja_kg[index]/1000,
              Cc_Ble_kg: this.$store.state.indicators.InputParametersYear.Cc_Ble_kg[index]/1000,
              AMV_kg: this.$store.state.indicators.InputParametersYear.AMV_kg[index],
              inp_Cfood_MILK: this.$store.state.indicators.InputParametersYear.inp_Cfood_MILK[index],
              inp_Cfood_CC: this.$store.state.indicators.InputParametersYear.inp_Cfood_CC[index],
            },
            Outputs:{
              Counts:{
                out_Ani_SH:this.$store.state.indicators.ProductionOutputsYear.out_Ani_SH[index],
                out_Ani_SC:this.$store.state.indicators.ProductionOutputsYear.out_Ani_SC[index],
                out_Ani_C:this.$store.state.indicators.HerdCharacteristicsYear.Nb_CulledCows[index],
              },
              Rates:{
                morRate:this.$store.state.indicators.TechnicalRatesYear.morRate[index],
                calfMorRate:this.$store.state.indicators.TechnicalRatesYear.calfMorRate[index],
                heifMorRateM1:this.$store.state.indicators.TechnicalRatesYear.heifMorRateM1[index],
                heifMorRateM6:this.$store.state.indicators.TechnicalRatesYear.heifMorRateM6[index],
              }
            },
            Pharmacy:{
              DCD:{
                DCD_heifers:this.$store.state.indicators.atbExposureYear.DCD_heifers[index],
                DCD_Cows:this.$store.state.indicators.atbExposureYear.DCD_Cows[index],
                DCD_imm_inLactCows:this.$store.state.indicators.atbExposureYear.DCD_imm_inLactCows[index],
              },
              ALEA:{
                ALEA_calves:this.$store.state.indicators.atbExposureYear.ALEA_calves[index],
                ALEA_heifers:this.$store.state.indicators.atbExposureYear.ALEA_heifers[index],
                ALEA_driedCows:this.$store.state.indicators.atbExposureYear.ALEA_driedCows[index],
              },
              Treats:{
                treatOnce:this.$store.state.indicators.atbExposureYear.treatOnce[index],
                h_treatOnce:this.$store.state.indicators.atbExposureYear.h_treatOnce[index],
              }
            },
            Flux:{
              DMY:this.$store.state.indicators.MilkProdYear.DMY[index],
              cullRate:this.$store.state.indicators.TechnicalRatesYear.cullRate[index],
              ReplacementRate:this.$store.state.indicators.TechnicalRatesYear.replacementRate[index],
              CcexpensePerMilk: this.$store.state.indicators.EconomicBookletYear.CcexpensePerMilk[index],
              Ani_Count: Math.round(this.$store.state.indicators.HerdCharacteristicsYear.M_Hsize[index]
                    +this.$store.state.indicators.HerdCharacteristicsYear.D_Hsize[index]),
            }
          }
          this.$store.commit('UPDATEINDICATORS', result)
          break;
        case "Mois":
          result={
            DiseasesPrev: {
              PrevSck:this.$store.state.indicators.DiseasesPrevMonth.PrevSck[index],
              PrevRpl:this.$store.state.indicators.DiseasesPrevMonth.PrevRpl[index],
              PrevMet:this.$store.state.indicators.DiseasesPrevMonth.PrevMet[index],
              PrevPvd:this.$store.state.indicators.DiseasesPrevMonth.PrevPvd[index],
              PrevLad:this.$store.state.indicators.DiseasesPrevMonth.PrevLad[index],
              PrevHca:this.$store.state.indicators.DiseasesPrevMonth.PrevHca[index],
              PrevSce:this.$store.state.indicators.DiseasesPrevMonth.PrevSce[index],
              PrevLame:this.$store.state.indicators.DiseasesPrevMonth.PrevLame[index],
            },
            Morbidity:{
              mastitis_count:this.$store.state.indicators.CellCMMonth.mastitis_count[index],
              CCMInc:this.$store.state.indicators.CellCMMonth.CCMInc[index],
              CMInc:this.$store.state.indicators.CellCMMonth.CMInc[index],
              CMperCow:this.$store.state.indicators.CellCMMonth.CMperCow[index],
              pVachesSup800:this.$store.state.indicators.CellCMMonth.pVachesSup800[index],
              pVachesInf300:this.$store.state.indicators.CellCMMonth.pVachesInf300[index],
            },
            Reproduction: {
            //   Rates:{
            //     FSCRL:undefined,
            //     CRC:undefined,
            //     PcVCs3IAL:undefined,
            //   },
            //   Ages:{
            //     HeifAgeCalv1:undefined,
            //     HeifAgeAIF:undefined,
            //   },
            //   CalvInterval:undefined,
            },
            Economy:{
              Incomes:{
                income_Milk: this.$store.state.indicators.EconomicBookletMonth.income_Milk[index],
                income_MeatCull: this.$store.state.indicators.EconomicBookletMonth.income_MeatCull[index],
                income_MeatCalfs: this.$store.state.indicators.EconomicBookletMonth.income_MeatCalfs[index],
                income_heifersRTC: this.$store.state.indicators.EconomicBookletMonth.income_heifersRTC[index],
              },
              Expenses:{
                expenses_Straw: this.$store.state.indicators.EconomicBookletMonth.expenses_Straw[index],
                expenses_Food: this.$store.state.indicators.EconomicBookletMonth.expenses_Food[index],
                expenses_Meds: this.$store.state.indicators.EconomicBookletMonth.expenses_Meds[index],
                expenses_Vet: this.$store.state.indicators.EconomicBookletMonth.expenses_Vet[index],
                expenses_Insem: this.$store.state.indicators.EconomicBookletMonth.expenses_Insem[index],

              }
            },
            HerdCharacteristics:{
              M_Hsize: this.$store.state.indicators.HerdCharacteristicsMonth.M_Hsize[index],
              D_Hsize: this.$store.state.indicators.HerdCharacteristicsMonth.D_Hsize[index],
              PreHerdSize: this.$store.state.indicators.HerdCharacteristicsMonth.preHerdSize[index],
              Nbirth_Male: this.$store.state.indicators.HerdCharacteristicsMonth.Nbirth_Male[index],
              Nbirth_Female: this.$store.state.indicators.HerdCharacteristicsMonth.Nbirth_Female[index],
            },
            MilkTank:{
              BTSCC: this.$store.state.indicators.CellCMMonth.BTSCC[index],
              BT_TP: this.$store.state.indicators.CellCMMonth.BT_TP[index],
              BT_TB: this.$store.state.indicators.CellCMMonth.BT_TB[index],
              Out_Mi_250: this.$store.state.indicators.ProductionOutputsMonth.out_Mi_250[index]/1000,
              Out_Mi_300: this.$store.state.indicators.ProductionOutputsMonth.out_Mi_300[index]/1000,
              Out_Mi_400: this.$store.state.indicators.ProductionOutputsMonth.out_Mi_400[index]/1000,
              Out_Mi_s400: this.$store.state.indicators.ProductionOutputsMonth.out_Mi_s400[index]/1000,
              milkTank: this.$store.state.indicators.ProductionOutputsMonth.out_Mi_250[index]/1000 +
                this.$store.state.indicators.ProductionOutputsMonth.out_Mi_300[index]/1000 +
                this.$store.state.indicators.ProductionOutputsMonth.out_Mi_400[index]/1000 +
                this.$store.state.indicators.ProductionOutputsMonth.out_Mi_s400[index]/1000
            },
            FoodInputs:{
              Ens_Mais_kg: this.$store.state.indicators.InputParametersMonth.Ens_Mais_kg[index]/1000,
              Foin_kg: this.$store.state.indicators.InputParametersMonth.Foin_kg[index]/1000,
              Trx_Soja_kg: this.$store.state.indicators.InputParametersMonth.Trx_Soja_kg[index]/1000,
              Cc_Ble_kg: this.$store.state.indicators.InputParametersMonth.Cc_Ble_kg[index]/1000,
              AMV_kg: this.$store.state.indicators.InputParametersMonth.AMV_kg[index],
              inp_Cfood_MILK: this.$store.state.indicators.InputParametersMonth.inp_Cfood_MILK[index],
              inp_Cfood_CC: this.$store.state.indicators.InputParametersMonth.inp_Cfood_CC[index],
            },
            Outputs:{
              Counts:{
                out_Ani_SH:this.$store.state.indicators.ProductionOutputsMonth.out_Ani_SH[index],
                out_Ani_SC:this.$store.state.indicators.ProductionOutputsMonth.out_Ani_SC[index],
                out_Ani_C:this.$store.state.indicators.HerdCharacteristicsMonth.Nb_CulledCows[index],
              },
            },
            Pharmacy:{
              DCD:{
                DCD_heifers:this.$store.state.indicators.atbExposureMonth.DCD_heifers[index],
                DCD_Cows:this.$store.state.indicators.atbExposureMonth.DCD_Cows[index],
                DCD_imm_inLactCows:this.$store.state.indicators.atbExposureMonth.DCD_imm_inLactCows[index],
              },
              ALEA:{
                ALEA_calves:this.$store.state.indicators.atbExposureMonth.ALEA_calves[index],
                ALEA_heifers:this.$store.state.indicators.atbExposureMonth.ALEA_heifers[index],
                ALEA_driedCows:this.$store.state.indicators.atbExposureMonth.ALEA_driedCows[index],
              },
              Treats:{
                treatOnce:this.$store.state.indicators.atbExposureMonth.treatOnce[index],
                h_treatOnce:this.$store.state.indicators.atbExposureMonth.h_treatOnce[index],
              }
            },
            Flux:{
              DMY:this.$store.state.indicators.MilkProdMonth.DMY[index],
              CcexpensePerMilk: this.$store.state.indicators.EconomicBookletMonth.CcexpensePerMilk[index],
              Ani_Count: Math.round(this.$store.state.indicators.HerdCharacteristicsMonth.M_Hsize[index]
                    +this.$store.state.indicators.HerdCharacteristicsMonth.D_Hsize[index]),
            }
          }
          this.$store.commit('UPDATEINDICATORS', result)
          break;
        case "Semaine":
          result={
            DiseasesPrev: {
              PrevSck:this.$store.state.weeklyActivities.Sck[index+520] || 0,
              PrevRpl:this.$store.state.weeklyActivities.Rpl[index+520] || 0,
              PrevMet:this.$store.state.weeklyActivities.Met[index+520] || 0,
              PrevPvd:this.$store.state.weeklyActivities.Pvd[index+520] || 0,
              PrevLad:this.$store.state.weeklyActivities.Lad[index+520] || 0,
              PrevHca:this.$store.state.weeklyActivities.Hca[index+520] || 0,
              PrevSce:this.$store.state.weeklyActivities.Sce[index+520] || 0,
              PrevLame:this.$store.state.weeklyActivities.Lame[index+520] || 0,
            },
            Morbidity:{
              mastitis_count:
                this.$store.state.weeklyActivities.Mcl_Esc_coli[index+520]+
                this.$store.state.weeklyActivities.Mcl_Kleb_spp[index+520]+
                this.$store.state.weeklyActivities.Mcl_Negc[index+520]+
                this.$store.state.weeklyActivities.Mcl_Oth[index+520]+
                this.$store.state.weeklyActivities.Mcl_Sta_aur[index+520]+
                this.$store.state.weeklyActivities.Mcl_Str_spp[index+520],
            },
            Reproduction: {
              FSCRL2: this.$store.state.weeklyActivities["nb_FSInsem"][index+520],
              CalvingCount: this.$store.state.weeklyActivities["nb_calv"][index+520],
              AI: this.$store.state.weeklyActivities["total_insems"][index+520],
            },
            Economy:{
              Incomes:{
                income_Milk: this.$store.state.weeklyActivities.milkIncome[index+520],
              },
              Expenses:{
                 expenses_Food: this.$store.state.weeklyActivities.feedCost[index+520],
              }
            },
            HerdCharacteristics:{
              M_Hsize: this.$store.state.counts["cows_Fist_Lactation"][index+520] + this.$store.state.counts["cows_SecondOrMore_Lactation"][index+520],
              D_Hsize: this.$store.state.counts["cows_dried"][index+520],
              PreHerdSize: this.$store.state.counts["heifers"][index+520],
              Nbirth_Male: this.$store.state.weeklyActivities["maleBirths"][index+520],
              Nbirth_Female: this.$store.state.weeklyActivities["femaleBirths"][index+520],
            },
            MilkTank:{
              BTSCC: this.$store.state.milkTank["cells_tank"][index+520],
              BT_TP: this.$store.state.milkTank["tp_tank"][index+520],
              BT_TB: this.$store.state.milkTank["tb_tank"][index+520],
              Out_Mi_250: this.$store.state.milkTank["out_Mi_250"][index+520]/1000,
              Out_Mi_300: this.$store.state.milkTank["out_Mi_300"][index+520]/1000,
              Out_Mi_400: this.$store.state.milkTank["out_Mi_400"][index+520]/1000,
              Out_Mi_s400: this.$store.state.milkTank["out_Mi_s400"][index+520]/1000,
              milkTank: this.$store.state.milkTank["milk_tank"][index+520]/1000,
            },
            Outputs:{
              Counts:{
                out_Ani_SH:this.$store.state.weeklyActivities["nb_SoldHeifRTC"][index+520],
                out_Ani_SC:this.$store.state.weeklyActivities["nb_SoldCalfs"][index+520],
                out_Ani_C:this.$store.state.weeklyActivities["nb_cull"][index+520],
              },
            },
            FoodInputs:{
              Ens_Mais_kg: this.$store.state.weeklyActivities.Ens_Mais_kg[index+520]/1000,
              Foin_kg: this.$store.state.weeklyActivities.Foin_kg[index+520]/1000,
              Trx_Soja_kg: this.$store.state.weeklyActivities.Trx_Soja_kg[index+520]/1000,
              Cc_Ble_kg: this.$store.state.weeklyActivities.Cc_Ble_kg[index+520]/1000,
              AMV_kg: this.$store.state.weeklyActivities.AMV_kg[index+520],
              inp_Cfood_MILK: this.$store.state.weeklyActivities.inp_Cfood_MILK[index+520],
              inp_Cfood_CC: this.$store.state.weeklyActivities.inp_Cfood_CC[index+520],
            },
            
            Pharmacy:{
              Treats:{
                treatOnce:this.$store.state.weeklyActivities.nb_cowsAtbTreatment[index+520],
                h_treatOnce:this.$store.state.weeklyActivities.nb_calvesAtbTreatment[index+520],
              }
            },
            Flux:{
              Ani_Count: Math.round(this.$store.state.counts["cows_Fist_Lactation"][index+520] 
                    + this.$store.state.counts["cows_SecondOrMore_Lactation"][index+520]
                    + this.$store.state.counts["cows_dried"][index+520]),
            }
          }
          this.$store.commit('UPDATEINDICATORS', result)
          break;
      }
    },
    chartReflow(){
      setTimeout(() => {
        for (let index5 = 0; index5 < this.$refs.chart.length; index5++) {
          this.$refs.chart[index5].chart.reflow()
        }
      }, 100);     
    },
    WindowWidthMgmt(){
      window.innerWidth < 1230? this.$store.commit("RESPONSIVE_MODE_MENU") : null
    },
    toggleToolTip(widgetIndex){
      this.tooltipDiv.includes(widgetIndex) ? this.tooltipDiv.splice(this.tooltipDiv.indexOf(widgetIndex),1) : this.tooltipDiv.push(widgetIndex)
    }, 
    handleScroll () {
      if(document.getElementById('accueil').getBoundingClientRect().y > -110){
           this.itemToHighligth = 'accueil'
      }else{
        for (let index = 0; index < this.widgets.length; index++) {
          if(document.getElementById(this.widgets[index].titleElt).getBoundingClientRect().y < 300){
            this.itemToHighligth = this.widgets[index].titleElt
          }
        }
      }
    }
  },
}
</script>

<style>
.menu, .menu2  {
  position:fixed;
  top:100px;
  bottom:0;
  max-height: 100%;
  width: 250px;
  z-index: 1;
  border-style: none solid none none;
  border-width: 0 2px 0 0;
  background-color:white;
  padding-top: 25px;
}
.menu{
  overflow-y:auto;
}
.menu2 {
  width: 45px;
}
.toggleBtn{
  display:flex;
  justify-content: flex-end;
  padding-right: 10px;
  height: 30px;
}
#obj{
  list-style: none;
}
#obj a{
  text-decoration: none;
}
.hidden {
    visibility: hidden;
}
.menu ul{
  padding-inline-start: 0px; 
}
.menu li{
  padding-top:3px;
  padding-bottom:3px;
  margin-bottom: 10px;
}
.menu li a{
  display:inline-block;
  width: 100%;
  color:black;
  font-size:24px;
  font-family: arial;
}
.menu li:hover{
  background-color:#E2EFF1;
}
.button{
  background-color: #0062cc;
  border: none;
  border-radius: 10px;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}
button:focus {
    outline: 0;
}
#chart {
  /* width: 100%; */
  padding-left: 40px;
  background: url("../assets/IMG_BackGroundTest50Fichier\ 46.png");
  padding-bottom:300px;
}
.ch1, .ch2, .ch3{
  background-color: white;
  padding: 3%; 
}
.ch1{
  height: 228px;
}
.ch2{
  height: 474px;
}
.ch3{
  height: 353px;
}
.widgetEltAccueil, .widgetElt{
  position: relative;
  /* left: 302px; */
    margin:auto;
  top:180px;
  width: 730px;
  border-style: solid;
  border-width: 4px;
  border-radius: 5px;
  margin-bottom: 30px;
}
.widgetEltAccueil{
  height: 480px;
} 
.toggleElt{
  /* left:100px; */
}
.legendElt{
  display: flex;
  font-weight: bold;
  font-size:24px;
  justify-content: center;
  place-items: center;
  background-color:#e3dbdb;
  border-style: none none solid none;
  border-width: 3px;
  padding: 0 3% 0 3%; 
  height:82px;
}
.highcharts-axis-labels span{
  z-index: -1;
}
p {
  text-indent: 0.5em;
}
.toolTipIcon{
  padding-left:5px;
  cursor: pointer;
}
.hoverHighlight{
    background-color:#bbe1e7;
}
#scenarioCode{
  font-size:13px;
  padding:10px;
  word-break:break-word;
}
@media (max-width: 1390px) {
#chart{
  padding-left:0px;
}
.toggleElt{
  left:unset;
}
.widgetEltAccueil, .widgetElt{
  left: 23px;
  margin:auto;
  margin-bottom:30px;
}
}
@media (max-width: 1000px) {
.widgetEltAccueil, .widgetElt{
  width: 85%;
}
}

</style>

import Vue from 'vue'
import App from './App.vue'
import HighchartsVue from "highcharts-vue";
import store from './store'
import loading from 'vuejs-loading-screen'

Vue.use(HighchartsVue);
Vue.use(loading, {
  bg: 'rgba(59, 121, 187)',
  icon: 'refresh',
  size: 3,
  icon_color: 'white',
})
Vue.config.productionTip = false

new Vue({
  store,
  render: h => h(App)
}).$mount('#app')

<template>
<div class="tooltip">
        <h3>Définitions:</h3>
        <div v-for=" (chart, index) in variablesToDefine.charts" v-bind:key="variablesToDefine.charts[index].title.text">
            <h4 v-if="chart.title.text">{{chart.title.text}}:</h4>
            <ul>
                <li v-for="(item, index2) in definitionsList[index].definitions" v-bind:key="index2">{{item.name}}: {{item.text}}</li>
            </ul>
        </div> 
</div>  
</template>

<script>
export default {
    name:'toolTipInfo',
    props:['variablesToDefine'],
    data(){
      return {
          definitionsList:[],
          tooltipElt:""
      }
    },
    mounted(){
        this.tooltipElt = this.$parent.jsonTooltip
        this.getToolTip(this.variablesToDefine)
    },
    methods:{
       getToolTip(variablesToDefine){

            for(let a=0; a < variablesToDefine.defs.length; a++){
            var list = {                
                title:"sometitle",
                definitions: []
            }
            this.definitionsList.push(list)
                for(let v=0; v< variablesToDefine.defs[a].length; v++){
                var defItem = {name:"", text:""}
                    for (let y = 0; y < Object.keys(this.tooltipElt).length; y++) {
                        if(Object.keys(this.tooltipElt)[y] == variablesToDefine.defs[a][v]){
                            defItem.name = Object.values(this.tooltipElt)[y][0]
                            defItem.text = Object.values(this.tooltipElt)[y][1]
                        }
                    }
                this.definitionsList[a].definitions.push(defItem)
                }
            }        
       },
    }
}
</script>

<style>
.tooltip{
    background-color: white;
    text-align: left;
    padding-left: 10px;
}
.tooltip li{
    line-height: normal;
    font-size:14px;
}
.tooltip h3{
    margin-block-start: 0;
    margin-block-end: 0;
}
.tooltip ul{
    margin-block-start: 0;
    margin-block-end: 0;
}
.tooltip h4{
    margin-block-start: 0.33em;
    margin-block-end: 0.33em;
}
</style>
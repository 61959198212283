<template>
  <div class="scenarioPage">
    <div class="scenarioItem">
        <div class="returnItem"><h3 @click="$parent.displayScenarioPage=false">&#60; Retour</h3></div>
                    <h2 class="scenarioHeader">Hygiène</h2>
                        <h3> Salle de traite</h3>
                            <input type="radio" id="B0" value="0" v-model="B">
                            <label for="B0">Très bon</label>
                            <input type="radio" id="B1" value="1" v-model="B">
                            <label for="B1">Bon</label>
                            <input type="radio" id="B2" value="2" v-model="B">
                            <label for="B2">Moyen</label>
                            <input type="radio" id="B3" value="3" v-model="B">
                            <label for="B3">Dégradé</label>
                            <input type="radio" id="B4" value="4" v-model="B">
                            <label for="B4">Très dégradé</label>

                        <h3>Bâtiment</h3>
                            <input type="radio" id="C0" value="0" v-model="C">
                            <label for="C0">Très bon</label>
                            <input type="radio" id="C1" value="1" v-model="C">
                            <label for="C1">Bon</label>
                            <input type="radio" id="C2" value="2" v-model="C">
                            <label for="C2">Moyen</label>
                            <input type="radio" id="C3" value="3" v-model="C">
                            <label for="C3">Dégradé</label>
                            <input type="radio" id="C4" value="4" v-model="C">
                            <label for="C4">Très dégradé</label>

                    <h2 class="scenarioHeader">Traitement au tarissement</h2>
                        <input type="radio" id="T1" value="1" v-model="T">
                        <label for="T1">Systématique</label>
                        <input type="radio" id="T2" value="2" v-model="T">
                        <label for="T2">Sélectif</label>
                        <input type="radio" id="T3" value="3" v-model="T">
                        <label for="T3">Sélectif + obturateur</label>


                    <h2 class="scenarioHeader">Alimentation</h2>
                        <input type="radio" id="A0" value="0" v-model="A">
                        <label for="10">Bon</label>
                        <input type="radio" id="A1" value="1" v-model="A">
                        <label for="A1">Moyen</label>
                        <input type="radio" id="A2" value="2" v-model="A">
                        <label for="A2">Dégradé</label>

                    <h2 class="scenarioHeader">Reproduction</h2>
                        <h3> Insémination artificielle sélective</h3>
                            <input type="radio" id="IA0" value="0" v-model="IA">
                            <label for="IA0">Inséminer toutes les vaches</label>
                            <br>
                            <input type="radio" id="IA1" value="1" v-model="IA">
                            <label for="IA1">Ne pas inséminer les vaches avec cétose (test diagnostic urinaire) et endométrite</label>
                            <br>
                            <input type="radio" id="IA2" value="2" v-model="IA">
                            <label for="IA2">Ne pas inséminer les vaches avec cétose (test diagnostic sanguin) et endométrite</label>
                            <br>

                        <h3> Détection des chaleurs</h3>
                            <input type="radio" id="DR25" value="25" v-model="DR">
                            <label for="DR25">0 minutes/jour: 25%</label>
                            <br>
                            <input type="radio" id="DR35" value="35" v-model="DR">
                            <label for="DR35">15mn/jour: 35%</label>
                            <br>
                            <input type="radio" id="DR45" value="45" v-model="DR">
                            <label for="DR45">30mn/jour: 45%</label>
                            <br>
                            <input type="radio" id="DR55" value="55" v-model="DR">
                            <label for="DR55">40mn/jour : 55%</label>
                            <br>
                            <input type="radio" id="DR65" value="65" v-model="DR">
                            <label for="DR65">2*25mn/jour : 65%</label>
                            <br>
                            <input type="radio" id="DR75" value="75" v-model="DR">
                            <label for="DR75">2*30mn/jour : 75%</label>
                            <br>

                        <h3> Période d'attente volontaire</h3>
                            <input type="radio" id="VWP05" value="05" v-model="VWP">
                            <label for="VWP05">5 semaines</label>
                            <br>
                            <input type="radio" id="VWP06" value="06" v-model="VWP">
                            <label for="VWP06">6 semaines</label>
                            <br>
                            <input type="radio" id="VWP07" value="07" v-model="VWP">
                            <label for="VWP07">7 semaines</label>
                            <br>
                            <input type="radio" id="VWP08" value="08" v-model="VWP">
                            <label for="VWP08">8 semaines</label>
                            <br>
                            <input type="radio" id="VWP09" value="09" v-model="VWP">
                            <label for="VWP09">9 semaines</label>
                            <br>
                            <input type="radio" id="VWP10" value="10" v-model="VWP">
                            <label for="VWP10">10 semaines</label>
                            <br>
                            <input type="radio" id="VWP11" value="11" v-model="VWP">
                            <label for="VWP11">11 semaines</label>
                            <br>
                            <input type="radio" id="VWP12" value="12" v-model="VWP">
                            <label for="VWP12">12 semaines</label>
                            <br>
                            <input type="radio" id="VWP13" value="13" v-model="VWP">
                            <label for="VWP13">13 semaines</label>
                            <br>

                    <h2 class="scenarioHeader">Boiteries</h2>

                        <h3>Fréquence de raclage</h3>
                            <input type="radio" id="Scraper_1" value="1" v-model="Scraper">
                            <label for="Scraper_1">Plus de 8 fois/ j</label>
                            <input type="radio" id="Scraper_2" value="2" v-model="Scraper">
                            <label for="Scraper_2">Moins de 8 fois/ j</label>

                        <h3>Type de sol</h3>
                            <input type="radio" id="HOU_1" value="1" v-model="HOU">
                            <label for="HOU_1">Sol lisse</label>
                            <input type="radio" id="HOU_2" value="2" v-model="HOU">
                            <label for="HOU_2">Sol rainuré</label>

                        <h3>Parage préventif</h3>
                            <input type="radio" id="TRIM_1" value="1" v-model="TRIM">
                            <label for="TRIM_1">Oui</label>
                            <input type="radio" id="TRIM_2" value="2" v-model="TRIM">
                            <label for="TRIM_2">Non</label>

                        <h3>Taux de détection des boiteries par l'éleveur</h3>
                            <input type="radio" id="LSDR_1" value="1" v-model="LSDR">
                            <label for="LSDR_1">Ne détecte pas</label>
                            <br>
                            <input type="radio" id="LSDR_2" value="2" v-model="LSDR">
                            <label for="LSDR_2">Moyenne - 80%</label>
                            <br>
                            <input type="radio" id="LSDR_3" value="3" v-model="LSDR">
                            <label for="LSDR_3">Moyenne - 60%</label>
                            <br>
                            <input type="radio" id="LSDR_4" value="4" v-model="LSDR">
                            <label for="LSDR_4">Moyenne - 40%</label>
                            <br>
                            <input type="radio" id="LSDR_5" value="5" v-model="LSDR">
                            <label for="LSDR_5"> Moyenne - 20%</label>
                            <br>
                            <input type="radio" id="LSDR_6" value="6" v-model="LSDR">
                            <label for="LSDR_6">Détection moyenne</label>
                            <br>
                            <input type="radio" id="LSDR_7" value="7" v-model="LSDR">
                            <label for="LSDR_7">Moyenne + 20%</label>
                            <br>
                            <input type="radio" id="LSDR_8" value="8" v-model="LSDR">
                            <label for="LSDR_8">Moyenne + 40%</label>
                            <br>
                            <input type="radio" id="LSDR_9" value="9" v-model="LSDR">
                            <label for="LSDR_9">Moyenne + 60%</label>
                            <br>
                            <input type="radio" id="LSDR_10" value="10" v-model="LSDR">
                            <label for="LSDR_10">Moyenne + 80%</label>
                            <br>
                            <input type="radio" id="LSDR_11" value="11" v-model="LSDR">
                            <label for="LSDR_11">Moyenne + 100%</label>
                            <br>

                        <h3>Seuil de prévalence de Mortellaro pour la mise en place d'un pédiluve</h3>
                            <input type="radio" id="FB0" value="0" v-model="FB">
                            <label for="FB0">Ne détecte pas</label>
                            <br>
                            <input type="radio" id="FB1" value="1" v-model="FB">
                            <label for="FB1">Moyenne - 80%</label>
                            <br>
                            <input type="radio" id="FB2" value="2" v-model="FB">
                            <label for="FB2">Moyenne - 60%</label>
                            <br>
                            <input type="radio" id="FB3" value="3" v-model="FB">
                            <label for="FB3">Moyenne - 40%</label>                            
                            <br>
                            <input type="radio" id="FB4" value="4" v-model="FB">
                            <label for="FB4"> Moyenne - 20%</label>
                            <br>
                            <input type="radio" id="FB5" value="5" v-model="FB">
                            <label for="FB5">Détection moyenne</label>
                            <br>
                            <input type="radio" id="FB6" value="6" v-model="FB">
                            <label for="FB6">Moyenne + 20%</label>
                            <br>
                            <input type="radio" id="FB7" value="7" v-model="FB">
                            <label for="FB7">Moyenne + 40%</label>
                            <br>
                            <input type="radio" id="FB8" value="8" v-model="FB">
                            <label for="FB8">Moyenne + 60%</label>
                            <br>
                            <input type="radio" id="FB99" value="99" v-model="FB">
                            <label for="FB99">Moyenne + 80%</label>
                            <br>


                <p class="error" v-if="!$store.state.disableSim">Le lancement de nouvelles simulations est actuellement bloqué à cause du trop grand nombre de demandes. Veuillez vous reconnecter ultérieurement.</p>
                <button class="button simBtns" @click="displayParamModal = true">Paramètres de simulation</button>
                <button class="button simBtns" :disabled='!$store.state.disableSim' @click="launchSim()">Simuler</button>
    </div>

            <simParamModal v-show="displayParamModal" />

  </div>
</template>

<script>
import axios from 'axios'
import config from '../../config.js'
import simParamModal from './modals/simParamModal.vue'

export default {
    name:'scenarioPage',
    components: {
      simParamModal
    },
    data(){
      return {
        B:"2",
        C:"2",
        T:"1",
        A:"1",
        IA:"2",
        DR:"55",
        VWP:"08",
        Scraper:"1",
        HOU:"1",
        TRIM:"1",
        LSDR:"6",
        FB:"3",
        displayParamModal:false,
        username:"",
        institution:"",
      }
    },
  mounted(){
    this.getUserName()
  },
  methods:{
        getUserName(){
            if(localStorage.getItem('userSession') == null){
                item = JSON.parse(localStorage.getItem('logDate'))
                this.username = item[0][1]+item[0][2]
                this.institution = JSON.parse(localStorage.getItem('logDate'))[0][8]
            }else{
                var item = JSON.parse(localStorage.getItem('userSession'))
                this.username = item.value[0][1]+item.value[0][2]
                this.institution = item.value[0][7]
            }
        },
        launchSim(){
            let vm = this
            let simString = ""
            Object.values(this.$store.state.simParams.sim_mgmt).map(x => simString += x.name+"="+x.value+"&")
            simString += "selected_scenario=S_T"+this.T+"_A"+this.A+"_HB"+this.B+"C"+this.C+"&reproScenario1=IA"+this.IA+"&reproScenario2=DR"+this.DR+"&reproScenario3=VWP"+this.VWP+"&username="+this.username+"&institution="+this.institution+"&Scraper=Scraper_"+this.Scraper+"&HOU=HOU_"+this.HOU+"&Trim=Trim_"+this.TRIM+"&FB=FB"+this.FB+"&LSDR=LSDR_"+this.LSDR+"&"
            Object.values(this.$store.state.simParams.prices).map(x => simString += x.name+"="+x.value+"&")
            Object.values(this.$store.state.simParams.med_prices).map(x => simString += x.name+"="+x.value+"&")
            simString = simString.substring(0, simString.length - 1)
            // console.log("simstring", simString)
            vm.$parent.editSimCount(1)
            this.$store.commit("EDIT_FEEDBACK_MSG", "Votre demande de simulation a bien été envoyée. Vous pouvez suivre son avancement via le bouton 'ampoule' de la barre de navigation.")
            this.$store.commit("DISPLAY_FEEDBACK_MODAL")
            axios.get(config.serverUrl+"/launch_sim/?"+simString)
            .then(response => {
                if(response.data.enableSim){
                    this.$store.commit("DISABLE_NEW_SIM", true)
                    //stockage nouvelle sim en localstorage et sur simwaitlist
                    var addSim = JSON.parse(localStorage.getItem("runningSim"))
                    addSim.push({id:response.data.simId, name:response.data.simName, state:response.data.simState, position:response.data.position})
                    localStorage.setItem("runningSim", JSON.stringify(addSim))                //ajout nouvelle sim dans lfe tableau réactif de simwaitlist
                    this.$root.$emit('addSim', response.data)
                    vm.$root.$emit('checkSimState', response.data.simId) 
                    this.$parent.getWaitingTime()
                } else {
                    vm.$parent.editSimCount(-1)
                    this.$store.commit("DISABLE_NEW_SIM", false)
                    this.$store.commit("EDIT_FEEDBACK_MSG", "Le lancement de nouvelles simulations est actuellement bloqué à cause du trop grand nombre de demandes. Veuillez vous reconnecter ultérieurement.")
                    this.$store.commit("DISPLAY_FEEDBACK_MODAL")
                    //on lance la boucle de requête pour màj quand c'est débloqué
                    this.$parent.canSimCheck()
                }
            })
            .catch(e=>{
                console.log(e);
                this.$store.commit("EDIT_FEEDBACK_MSG", "Un problème est survenu. Veuillez relancer une simulation ultérieurement.")
                this.$store.commit("DISPLAY_FEEDBACK_MODAL")
                this.$parent.editSimCount(-1)
            })
        },
        canSimCheck(){
            var institution
            if(localStorage.getItem('userSession') == null){
                institution = JSON.parse(localStorage.getItem('logDate'))[0][8]
            }else{
                var item = JSON.parse(localStorage.getItem('userSession'))
                institution = item.value[0][7]
            }
            axios.get(config.serverUrl+"/can_sim/?institution="+institution)
            .then(response=>{
                if (response.data.enableSim) {
                    this.$store.commit("DISABLE_NEW_SIM", true)
                } else {
                    this.$store.commit("DISABLE_NEW_SIM", false)
                }
            })
            .catch(e=>{console.log(e)})
        }
    }
}
</script>

<style>
.scenarioItem{
    position: absolute;
    margin: auto;
    top: 5px;
    right: 0;
    bottom: 0;
    left: 0;
    width:90%;
    height: 71%;
    background-color: rgba(255, 255, 255, 0.7);
    overflow: auto;
    padding-bottom: 10px;
}
.returnItem{
    text-align: left;
    width:90px;
    cursor:pointer;
    padding-left:10px;
    padding-top:5px;
}
#scenarioParams{
  display: flex;
  justify-content: space-around;
}
#scenarioParams {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}
.tableWidth{
  width:100%
}
.simBtns2 {
    padding: 9px 5px !important;
}
.scenarioHeader{
    width:98%;
    border-style: none none solid none;
    border-width:  0 0 1px 0;
}
.error{
    color:red;
}
@media (max-width: 600px) {

.scenarioItem {
    position: sticky;
    /* margin: auto; */
    top: 150px;
    /* right: 0;
    bottom: unset;
    height: unset;
    overflow: unset; */
}
.scenarioPage{
    padding-bottom:100px;
}
}
</style>
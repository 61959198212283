<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
        <div class="modal-container2">
            <div id="infoModal">
                <div class="modalItem">
                    <p>Un compte sera créé pour vous permettre de vous connecter au site sans passer par moodle. Il sera valable 3 mois. Veuillez renseigner les informations ci-dessous:</p>
                </div>
                <div class="modalItem"> 
                    <!-- <label for="password1">Mot de passe </label> -->
                    <input  class="registerInput3" type="password" placeholder="Mot de passe" name="password1" v-model="password1" @change="password1Validate()">
                    <div id="password1Error">Le mot de passe doit contenir au moins 8 caractères, dont une majuscule, une minuscule, un caractère spécial et un nombre</div>
                </div>
                <div class="modalItem">
                    <!-- <label for="password2">Confirmation du mot de passe </label> -->
                    <input class="registerInput3" type="password" placeholder="Confirmation du mot de passe" name="password2" v-model="password2"   @change="password2Validate()">
                    <div class="error" v-if="password2Error">Vos mots de passe doivent être  identiques</div>
                </div>
                <div class="modalItem">
                    <label  for="institution" class="custom-select4 select5">
                    <select class="simBtns4" name="institution" v-model="institution">
                        <option value="Ecole" disabled >Ecole</option>
                        <option value="ENVT">ENVT</option>
                        <option value="Agreenium">Agreenium</option>
                        <option value="Autre">Autre</option>
                    </select>
                    </label>
                </div>
                <div class="modalItem"> 
                    <label for="occupation" class="custom-select4 select5">
                    <select class="simBtns4"  name="occupation" v-model="role">
                        <option value="Rôle" disabled >Rôle</option>
                        <option value="Etudiant">Etudiant</option>
                        <option value="Enseignant">Enseignant</option>
                        <option value="Autre">Autre</option>
                    </select>
                    </label>
                </div>
                <div class="modalItem" v-if="role =='Etudiant'">
                    <!-- Précisez l'année d'études           -->       
                    <label  for="year" class="custom-select4 select5"> 
                    <select class="simBtns4"  name="year" v-model="year">
                        <option value="Année étude" disabled >Année d'étude</option>
                        <option :value="1">1</option>
                        <option :value="2">2</option>
                        <option :value="3">3</option>
                        <option :value="4">4</option>
                        <option :value="5">5</option>
                        <option :value="'>5'">Supérieure à 5</option>
                    </select>
                    </label>
                </div>
                <div class="modalItem">
                    <p>Votre compte devra être validé par l'administrateur avant d'être utilisable. Vous pourrez toujours accéder au site via moodle.</p>
                    <button class="button2" :disabled='password2Error || password2=="" || institution=="Ecole" || role=="Rôle" || role=="Etudiant" && year =="Année étude"' @click="sendInfo()">Envoyer</button>
                </div>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import config from '../../../config.js'

export default {
  name:"accountInfoModal",
  data(){
    return {
        role:"Rôle",
        institution:"Ecole",
        year:"Année étude",
        password1:"",
        password2:"",
        password2Error:false,
    }
  },
  methods:{
        sendInfo(){
            this.role != "Etudiant" ? this.year=null : null
            let vm=this
            axios.post(config.serverUrl+"/moodleAccountInfo/",
            {
                email:this.$store.state.moodleEmail,
                role:this.role,
                year:this.year,
                password:this.password1,
                institution:this.institution
            },         
            {  headers: { 'content-type': 'text/plain' }
            })    
            .then(response => {
              let date = Date.now()
              response.data.userInfo[0].push(date)
              localStorage.setItem('logDate', JSON.stringify(response.data.userInfo))
              vm.$store.commit("DISPLAY_ACCOUNT_INFO_MODAL")      
            }).catch(e => {
                console.log(e)
            })
        },
        password1Validate(){
            var regex=/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&"'=+{}.,:;#/])[A-Za-z\d@$!%*?&"'=+{}.,:;#/]{8,}$/
            regex.test(this.password1) ? document.getElementById("password1Error").style.color = "black" : document.getElementById("password1Error").style.color = "red";
        },
        password2Validate(){
            this.password2Error = false
            this.password1 == this.password2 ? null : this.password2Error = true
        },
    }
} 
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container2 {
  width: 50%;
  /*height: 80%; */
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  overflow:auto;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

input[type="number"]{
  width:140px;
}
.paramInput{
  text-align:center;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
th, td{
  width:25%;
}
#infoModal{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items:center;
}
.error{
    color:red;
}
.modalItem{
  width:91%;
  margin-top: 5px;
  margin-bottom: 5px;
}
.simBtns4 {
    font-size: 13.33px;
    font-family: arial;
    padding: unset !important;
    width: -webkit-fill-available !important;
}
.custom-select4 {
  width: 130px;
  position: relative;
  /* margin: 4px 2px; */
  border: 1px solid white;
  z-index: 10;
  background-color: #0062cc;
  border-radius: 10px;
}
.custom-select4:after {
  position: absolute;
  right: 0;
  top: 0;
  width: 37px;
  height: 100%;
  line-height: 38px !important;
  content: url("../../assets/down-arrow.png");
  text-align: center;
  color: white;
  font-size: 22px;
  border-left: 1px solid white;
  padding-top: 8px;
  z-index: -1;
}
.select5 {
    width: 54% !important;
    height:40px;
    padding-left: 0.7em;
    box-shadow: #004da0 6px 6px 0px 0px inset;
    border: unset !important;
    display: inline-block;
}
.registerInput3 {
    width: 54%;
    height: 40px;
    border-radius: 10px;
    border: 0px;
    color:white;
    box-shadow: #004da0 6px 6px 0px 0px inset;
    background-color: #0062cc !important;
    padding-left: 0.7em;
    outline: none;
    appearance: none;
    -webkit-appearance: none;
}
.registerInput3::placeholder {
    color: white;
    text-indent: 0.5em;
}
.registerInput3:-webkit-autofill,
.registerInput3:-webkit-autofill:hover,
.registerInput3:-webkit-autofill:focus,
.registerInput3:-webkit-autofill:active {
    -webkit-box-shadow: #004da0 6px 6px 0px 0px inset !important;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: white !important;
}
#password1Error{
  margin-top: 5px;
}
/* @media (max-width: 1000px) {
#infoModal{
  display: block;
  margin:auto;
}
} */
</style>
<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
        <div class="modal-container">
            <div v-if="$store.state.feedbackMsg == 'Votre demande de simulation a bien été envoyée. Vous pouvez suivre son avancement via le bouton \'ampoule\' de la barre de navigation.'">
              <img id="imgwait" src="../assets/output_c5hJL0.gif">
            </div>
            <div class="feedbackMsg">
            <p>{{$store.state.feedbackMsg}}</p>
            </div>
            <div class="modal-footer">
                <button class="button simBtns" @click="close()">
                OK
                </button>
            </div>
        </div>
        </div>
    </div>
</template>

<script>

export default {
    name:'feedbackModal',
    data(){
      return {

      }
    },
    methods:{
        close(){
            if (this.$parent.register==true) {
                this.$parent.register=false
            }
            this.$store.commit('HIDE_FEEDBACK_MODAL')
            this.$store.commit("EDIT_FEEDBACK_MSG", "")                

        }
    }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  height: auto;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.feedbackMsg>p{
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;

}
.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

/* .modal-default-button {
  float: right;
} */
#imgwait{
      width: 90%;
    height: 86%;
    padding-bottom: 10px;
    -o-object-fit: fill;
    object-fit: fill;
}
.simBtns{
  font-size: 14px;
  padding: 11px 22px;
}
.button{
  background-color: #0062cc;
  border: none;
  border-radius: 10px;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}
</style>
<template>
    <div class="login">
        <div id="lastUpdate">
            Dernière mise à jour: 09/03/2022
        </div>
        <div class="loginItem">
            <div id="loginForm" >
                    <div class="transparentBackground">
                    <img id="loginImg"  src="../assets/logo complet light.png">
                    <p> Bienvenue sur le serveur Dairy Health Sim.</p>
                        <div id=flexItm v-if="!displayPasswordReset && !$store.state.displayPwdUpdate">
                            <div class="apparentBackground switchBtn" v-bind:class="{switchBtnOff: this.register}"><a @click="switchBtn()">Connexion</a></div>
                            <div class="apparentBackground switchBtn" v-bind:class="{switchBtnOff: !this.register}"><a @click="switchBtn()">Inscription</a></div>
                            <div></div>
                       </div>
                    </div>
                    <!-- password reset request -->
                    <div v-if="displayPasswordReset" class='pwdResetItem apparentBackground'>
                        <p>Veuillez renseigner votre email. Un message vous sera envoyé pour réinitialiser votre mot de passe.</p>
                        <input class="loginIpunt registerInput" type="text" placeholder="Email" v-model="emailPwdReset">
                        <p v-if="emailPwdResetError" class="error">Email invalide</p>
                        <p v-if="noMailFound" class="error">Votre email n'est pas présent dans notre base de données.</p>
                        <button class="sendBtn" @click="pwdReset()">Envoyer</button>
                    </div>
                    <!-- password reset submission -->
                    <div v-if="$store.state.displayPwdUpdate" class='pwdResetItem apparentBackground'>
                        <pwdUpdate />
                    </div>
                    <!--login -->
                    <div v-if="!register &&!displayPasswordReset && !$store.state.displayPwdUpdate" id="loginForm2" class="apparentBackground">
                        <p>Veuillez entrer votre email et votre mot de passe pour accéder au simulateur.</p>
                        <p class="error" v-if="accountExpired">La durée de validité de votre compte a expiré. Veuillez en recréer un.</p>
                        <p class="error" v-if="$store.state.tokenExpired">Token expiré</p>
                        <p class="error" v-if="$store.state.errorPwd">Vos identifiants sont incorrects ou votre compte n'a pas encore été validé par l'administrateur.</p>
                        <p class="error" v-if="$store.state.accessDenied">Un problème est survenu, veuillez réessayer plus tard.</p>
                        <input class="loginIpunt registerInput" type="text" placeholder="Email" v-model="email">
                        <input class="loginIpunt registerInput" type="password"  placeholder="Mot de passe" v-model="pwd">
                        <button class="sendBtn" @click="login()">Se connecter</button>
                        <p><a href="" @click.prevent="displayPasswordReset=true">Mot de passe oublié?</a></p>
                    <!-- register -->
                    </div>
                        <Register v-if="register &&!displayPasswordReset" />
                    </div>
        </div>
        <LogoFooter />
    </div>
</template>

<script>
import axios from 'axios'
import config from '../../config.js'
import LogoFooter from './footer.vue'
import Register from './register.vue'
import pwdUpdate from './pwdUpdate.vue'
export default {
    name:'entryPage',
    components: {
        LogoFooter,
        Register,
        pwdUpdate,
    },
    data(){
        return {
            pwd:'',
            email:'',
            register:false,
            accountExpired:false,
            displayPasswordReset:false,
            emailPwdReset:"",
            emailPwdResetError:false,
            noMailFound:false,
        }
    },
    mounted () {
        window.addEventListener('keyup', event => {
            if (event.key === 'Enter') { 
                this.login()
            }
        })
    },
    methods:{
        switchBtn(){
            this.register=!this.register
        },
        login(){
            axios.post(config.serverUrl+"/newLogin/",
                {
                    email:this.email,
                    password:this.pwd,
                },         
                {  headers: { 'content-type': 'text/plain' }
            }).then(response => {
                console.log("resp", response)
                // Le compte est toujours valide?
                if (response.data.accountExpired){
                    this.accountExpired = true
                }else{
                    this.$store.commit('SET_STATUS', response.data.userInfo[0][4])
                    this.$store.commit('LOGGED')
                    localStorage.setItem('userSession', JSON.stringify({value:response.data.userInfo, expiry:Date.now()+2592000000}))
                    if(localStorage.getItem("runningSim")===null){
                        const emptyArray = []
                        localStorage.setItem("runningSim", JSON.stringify(emptyArray))
                    }
                }
            }).catch(e => {
                if(e.response.status == 401){
                     this.$store.commit('ERROR_PWD')
                } else {
                     this.$store.commit('ACCESS_DENIED')
                }
            })
        },
        pwdReset(){
            this.noMailFound=false
            this.emailPwdResetError = false
            var regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
            if(regex.test(this.emailPwdReset)){
                let vm=this
                axios.post(config.serverUrl+"/pwdReset/",
                    {
                        email:this.emailPwdReset,
                    },         
                    {  headers: { 'content-type': 'text/plain' }
                }).then(response => {
                    // cas ou le mail n'est pas dans la bdd
                    if(response.data.userInfo === "noMailFound"){
                        vm.noMailFound = true
                    } else{
                        vm.displayPasswordReset = false
                        vm.$store.commit("EDIT_FEEDBACK_MSG", "Un email de réinitialisation de mot de passe vous a été envoyé.")
                        vm.$store.commit("DISPLAY_FEEDBACK_MODAL")
                    }
                }).catch(e => {
                    console.log(e)
                })
            }else{
                this.emailPwdResetError = true
            }

        },
    }
}
</script>

<style>
.switchBtnOff{
    background-color: rgba(255, 255, 255, 0)!important;
}
.switchBtn{
    width:150px;
    font-size:19px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
}
.switchBtn>a{
    text-decoration: none;
    color:black;
    height: 100%;
    width:100%;
    text-align: center ;
    cursor: pointer;
    display: contents;
}
#flexItm{
    display: flex;
    justify-content: left;
    height: 50px;
}
.transparentBackground{
    background-color: transparent;
    width:90%;
}
.apparentBackground{
    background-color: rgba(255, 255, 255, 0.7);
}
#loginForm2{
  display: flex;
  flex-direction: column; 
  justify-content: space-evenly; 
  align-items: center;
  width:90%;
  height: 50%;
  overflow: auto;
}
#loginForm2>p{
    width:66%;
    font-size: 14px;
}
.pwdResetItem>p{
    font-size: 14px;
}
.login{
  height: 100%;
}
.loginItem{
  /* background: url("../assets/fond-troupeau5test.jpg"); */
    /* background: url("../assets/fond-troupeau6test.jpg"); */
  background: url("../assets/fond-troupeau2.jpg");
  background-size: cover;
  height:100%;
  background-position-y: 100%;
}
#loginForm{
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 70px;
  left: 0;  
  width: 46%;
  height: 78%;
  display: flex;
  flex-direction: column; 
  justify-content: center; 
  align-items: center;
}
#loginImg{
  width:250px;
}
.pwdResetItem{
  display: flex;
  flex-direction: column; 
  justify-content: center; 
  align-items: center;
  padding-bottom:1em;
}
#lastUpdate{
  position: fixed;
  top: 0;
  left: 0;
  right:0;
  padding-top:5px;
}
.button2{
  background-color: #3b79bb;
  border: none;
  border-radius: 10px;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}
.loginIpunt{
  margin-bottom: 5px;
}
.sendBtn{
    border-radius: 5px;
    width: 40%;
    box-shadow: none;
    background-color: black !important;
    cursor: pointer;
    color:white;
    height:40px;
    outline: none;
}
.registerInput{
    filter:none;
    width: 54%;
    height: 40px;
    border-radius: 10px;
    border: 0px;
    color:white;
    box-shadow: #004da0 6px 6px 0px 0px inset !important;
    background-color: #0062cc !important ;
    padding-left: 0.7em;
    outline: none;
    opacity:  1;
    appearance: none;
    -webkit-appearance: none;
}
.registerInput::placeholder {
    filter:none;
    opacity:  1;
    color: white!important;
}
.registerInput:-webkit-autofill,
.registerInput:-webkit-autofill:hover,
.registerInput:-webkit-autofill:focus,
.registerInput:-webkit-autofill:active {
    -webkit-box-shadow: #004da0 6px 6px 0px 0px inset !important;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: white !important;
}
@media (max-height: 550px) {
#loginForm{
    height:80%;
    padding-bottom: 10px;
    padding-top: 10px;
}
.loginItem {
    padding-bottom: 90px;
    overflow: scroll;
}
/* p {
    margin-block-start: 0.3em;
    margin-block-end: 0.3em;
} */
}
@media (max-width: 1025px) {
#loginForm{
    width:80%;
    bottom:33px;
}
}
@media (max-width: 400px) {
#loginImg{
    width:150px;
}
.loginIpunt{
    width:90%;
}
}
</style>
<template>
    <div class="modal-mask6">
        <div class="modal-wrapper6">
        <div class="modal-container1">
            <div class="modal-footer">
                <button class="modal-default-button" @click="close()">X</button>
            </div>
            <div id="flexParams">
              <div class="tableWidth">
                <table>
                  <thead>
                    <tr>
                      <th colspan="8">Inscriptions en attente de confirmation :</th>
                    </tr>
                    <tr>
                      <th>Email</th>
                      <th>Nom</th>
                      <th>Prénom</th>
                      <th>Ecole</th>
                      <th>Rôle</th>
                      <th>Année (si élève)</th>
                      <th colspan="2">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(user,index) in usersToConfirm" :key="user.email">
                      <td >{{user.email}}</td>
                      <td >{{user.firstName}}</td>
                      <td >{{user.lastName}}</td>
                      <td >{{user.institution}}</td>
                      <td >{{user.role}}</td>
                      <td >{{user.year}}</td>
                      <td ><button class="button2" @click="confirm(user.email), updateTable(index)">Confirmer</button></td>
                      <td ><button class="button2" @click="reject(user.email), updateTable(index)">Refuser</button></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import config from '../../../config.js'

export default {
  name:"userConfirmModal",
  data(){
    return {
        usersToConfirm:[],
    }
  },
  created(){
      this.getUsersToConfirm()
  },
  methods:{
        close(){
            this.$parent.displayUserConfirmModal = false
        },
        getUsersToConfirm(){
            let vm=this
            axios.get(config.serverUrl+"/displayUsersToConfirm/")    
            .then(response => {
                vm.usersToConfirm= response.data.userInfo
                // console.log(this.usersToConfirm)
            }).catch(e => {
                console.log(e)
            })
        },
        async confirm(email){
            console.log('user:',email)
            await axios.post(config.serverUrl+"/userConfirm/", 
                {
                    email:email,
                },         
                {  headers: { 'content-type': 'text/plain' }})
        },
        async reject(email){
            await axios.post(config.serverUrl+"/userReject/",
                {
                    email:email,
                },         
                {  headers: { 'content-type': 'text/plain' }})
        },
        updateTable(index){
            this.usersToConfirm.splice(index, 1)
            this.$parent.numberOfUsersToConfirm -= 1
        },
    }
} 
</script>

<style scoped>
.modal-mask6 {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper6 {
  display: table-cell;
  vertical-align: middle;
}

.modal-container1 {
  width: 90%;
  height: 70%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  overflow:auto;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}
input[type="number"]{
  width:140px;
}
.paramInput{
  text-align:center;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container1,
.modal-leave-active .modal-container1 {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
th, td{
  width:25%;
}
#flexParams{
  display: flex;
  justify-content: space-around;
}
#flexParams {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#flexParams td, #flexParams th {
  border: 1px solid #ddd;
  padding: 8px;
}

#flexParams tr:nth-child(even){background-color: #f2f2f2;}

#flexParams tr:hover {background-color: #ddd;}

#flexParams th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #003772;
  color: white;
}
.tableWidth{
  width:100%
}
@media (max-width: 1000px) {
#flexParams{
  display: block;
  margin:auto;
}
}
@media (max-width: 830px) {
.modal-container1 {
  width: 500px;
}
}
@media (max-width: 570px) {
.modal-container1 {
  width: 300px;
}
}
@-moz-document url-prefix() {
  .modal-wrapper6 {
    display: flex !important;
    align-items:center !important;
  }
  .modal-mask6{
    display:flex !important;
    justify-content: center !important;
  }
}
</style>
<template>
    <div class="modal-mask5">
        <div class="modal-wrapper5">
        <div class="modal-container5">
            <div class="modal-footer">
                <button class="modal-default-button" @click="close()">X</button>
            </div>
            <div id="flexParams">
              <div class="tableWidth">
                <table>
                  <thead>
                    <tr>
                      <th colspan="8">Utilisateurs confirmés :</th>
                    </tr>
                    <tr>
                        <th colspan="8">Recherche <input @keyup="filterUsers()" v-model="input" type="text"></th>
                    </tr>
                    <tr>
                      <th>Email</th>
                      <th>Nom</th>
                      <th>Prénom</th>
                      <th>Ecole</th>
                      <th>Rôle</th>
                      <th>Année (si élève)</th>
                      <th>Date d'inscription</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(user,index) in usersToDisplay" :key="user.email">
                      <td >{{user.email}}</td>
                      <td >{{user.firstName}}</td>
                      <td >{{user.lastName}}</td>
                      <td >{{user.institution}}</td>
                      <td >
                        <label  for="occupation" class="custom-select select2 select3">
                        <select class="select2 simBtns2" name="occupation" v-model="user.role" @change="roleUpdate(index, user.role)">
                            <option 
                            v-for="option in options" 
                            :key="option"
                            :selected="option == user.role"
                            >{{ option }}</option>
                            <!-- <option value="Aministrateur">Administrateur</option>
                            <option value="Etudiant">Etudiant</option>
                            <option value="Enseignant">Enseignant</option>
                            <option value="Autre">Autre</option> -->
                        </select>
                        </label>                          

                      </td>
                      <td >{{user.year}}</td>
                      <td >{{user.registerDate}}</td>
                      <td ><button class="button2" @click="reject(user.email), updateTable(index)">Supprimer</button></td>
                    </tr>
                    <tr v-if="noUsers" colspan="8">Pas d'utilisateurs disponibles</tr>
                  </tbody>
                </table>
              </div>
            </div>
            <updateYearModal v-if="displayYearModal" :index=selectedIndex :newRole=selectedNewRole />
        </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import config from '../../../config.js'
import updateYearModal from './updateYearModal.vue'

export default {
  name:"accountAdminModal",
  components:{
    updateYearModal
  },
  data(){
    return {
        users:[],
        usersToDisplay:[],
        input:"",
        options:["Administrateur", "Enseignant", "Etudiant", "Autre"],
        displayYearModal:false,
        selectedIndex:"",
        selectedNewRole:"",
        noUsers:false
    }
  },
  created(){
      this.getUsers()
  },
  methods:{
        close(){
            this.$parent.displayAccountAdminModal = false
        },
        filterUsers(){
            this.noUsers=false
            let vm=this
            axios.post(config.serverUrl+"/userSearch/",
                {
                    keyword:this.input,
                },         
                {  headers: { 'content-type': 'text/plain' }}
            )    
            .then(response => {
                console.log("rep", response)
                if(response.data.users=="none"){
                  vm.users=[]
                  vm.usersToDisplay=[]
                  vm.noUsers=true
                } else {
                  vm.users= response.data.users
                  vm.usersToDisplay= response.data.users
                }
            }).catch(e => {
                console.log(e)
            })
        },
        getUsers(){
            let vm=this
            axios.get(config.serverUrl+"/displayUsers/")    
            .then(response => {
                vm.users= response.data.userInfo
                vm.usersToDisplay= response.data.userInfo
                console.log("rep", response.data.userInfo)
            }).catch(e => {
                console.log(e)
            })
        },
        async reject(email){
            await axios.post(config.serverUrl+"/userReject/",
                {
                    email:email,
                },         
                {  headers: { 'content-type': 'text/plain' }})
        },
        updateTable(index){
            this.usersToDisplay.splice(index, 1)
        },
        roleUpdate(index, newRole){
          console.log(index)
          console.log("newRole", newRole)
          this.selectedIndex=index,
          this.selectedNewRole=newRole
          newRole =="Etudiant"? this.displayYearModal = true : this.updateUser(index, newRole)
        },
        updateUser(index, newRole, newYear=null){
                    console.log(index)
                    console.log("year",newYear)
          console.log("newRole", newRole)
            axios.post(config.serverUrl+"/userUpdate/",
                {
                    email:this.users[index].email,
                    role:newRole,
                    year:newYear,
                },         
                {  headers: { 'content-type': 'text/plain' }}
            )    
            .then(response => {
                console.log("rep", response)
            }).catch(e => {
                console.log(e)
            })
        }
    }
} 
</script>

<style scoped>
 .select3{
  width:83% !important;
}
.modal-mask5 {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper5 {
  display: table-cell;
  vertical-align: middle;
}

.modal-container5 {
  width: 90%;
  height: 70%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  overflow:auto;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}
input[type="number"]{
  width:140px;
}
.paramInput{
  text-align:center;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container5,
.modal-leave-active .modal-container5 {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
th, td{
  width:25%;
}
#flexParams{
  display: flex;
  justify-content: space-around;
}
#flexParams {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#flexParams td, #flexParams th {
  border: 1px solid #ddd;
  padding: 3px;
  font-size:13px;
}

#flexParams tr:nth-child(even){background-color: #f2f2f2;}

#flexParams tr:hover {background-color: #ddd;}

#flexParams th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #003772;
  color: white;
}
.tableWidth{
  width:100%
}
.simBtns2 {
    padding: 9px 5px !important;
    width: 150px !important;
}
.button2 {
    background-color: #3b79bb;
    border: none;
    border-radius: 10px;
    color: white;
    padding: 15px 16px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
}
@media (max-width: 1000px) {
#flexParams{
  display: block;
  margin:auto;
}
}
@media (max-width: 830px) {
.modal-container5 {
  width: 500px;
}
}
@media (max-width: 570px) {
.modal-container5 {
  width: 300px;
}
}
@-moz-document url-prefix() {
  .modal-wrapper5 {
    display: flex !important;
    align-items:center !important;
  }
  .modal-mask5{
    display:flex !important;
    justify-content: center !important;
  }
}
</style>
<template>
    <div class="paddingFix">
        <div class="error" v-if="incompleteForm">
            <p>Tous les champs doivent être complétés.</p>
        </div>
        <input class="loginIpunt registerInput" type="text" placeholder="Email" v-model="emailPwdReset"  @change="emailValidate()">
        <p v-if="emailPwdResetError" class="error">Email invalide</p>
        <p v-if="noMailFound" class="error">Votre email n'est pas présent dans notre base de données.</p>
        <div>
            <input class="registerInput" type="password"  placeholder="Nouveau mot de passe" name="password1" v-model="passwordReset1" @keyup="password1Validate()">
        </div>
            <div id="passwordError2"><p>Le mot de passe doit contenir au moins 8 caractères, dont une majuscule, une minuscule, un caractère spécial et un nombre</p></div>
        <div>
            <input class="registerInput" type="password" placeholder="Confirmation du mot de passe" name="password2" v-model="passwordReset2"   @keyup="password2Validate()">
            <div class="error" v-if="passwordReset2Error"><p>Vos mots de passe doivent être  identiques</p></div>
        </div>
        <button class="sendBtn" @click="validate()">Envoyer</button>
    </div>
</template>

<script>
import axios from 'axios'
import config from '../../config.js'

export default {
    name:"pwdUpdate",
    data(){
        return {
            emailPwdReset:"",
            emailPwdResetError:false,
            noMailFound:false,
            passwordReset1:"",
            passwordReset2:"",
            passwordReset2Error:false,
            incompleteForm:false,
            emailError:false
        }
    },
    methods:{
        emailValidate(){
            this.emailError = false
            var regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
            regex.test(this.email) ? null : this.emailError = true
        },
        password1Validate(){
            var regex=/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&"'=+{}.,:;#/])[A-Za-z\d@$!%*?&"'=+{}.,:;#/]{8,}$/
            regex.test(this.passwordReset1) ? document.getElementById("passwordError2").style.color = "black" : document.getElementById("passwordError2").style.color = "red";
        },
        password2Validate(){
            this.passwordReset2Error = false
            this.passwordReset1 == this.passwordReset2 ? null : this.passwordReset2Error = true
        },
        validate(){
            this.incompleteForm = false
            if(this.passwordReset1 == "" || this.passwordReset2==""){
                this.incompleteForm = true
            } else {
                this.pwdUpdate()
            }
        },
        pwdUpdate(){
            this.noMailFound=false
            this.emailPwdResetError = false
            var regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
            if(regex.test(this.emailPwdReset)){
                let vm=this
                axios.post(config.serverUrl+"/pwdUpdate/",
                    {
                        email:this.emailPwdReset,
                        token:this.$store.state.resetToken,
                        password:this.passwordReset2
                    },         
                    {  headers: { 'content-type': 'text/plain' }
                }).then(response => {
                    if(response.data.userInfo === "noMailFound"){
                        vm.noMailFound = true
                    } else{
                        console.log("success")
                        vm.$store.commit("DISPLAY_PWD_UPDATE", null)
                        vm.$store.commit("EDIT_FEEDBACK_MSG", "Votre nouveau mot de passe a bien été pris en compte, vous pouvez maintenantt vous connecter au site.")
                        vm.$store.commit("DISPLAY_FEEDBACK_MODAL")
                    }
                }).catch(e => {
                    console.log(e)
                    if(e.response.status == 403){
                        vm.$store.commit("DISPLAY_PWD_UPDATE", null)
                        vm.$store.commit("EDIT_FEEDBACK_MSG", "Votre lien a expiré. Veuillez renouveller votre demande.")
                        vm.$store.commit("DISPLAY_FEEDBACK_MODAL")
                    }
                })
            }else{
                this.emailPwdResetError = true
            }

        }
    }
}
</script>

<style>
.paddingFix{
    padding-top:10px;
}
.sendBtn{
    margin-top:10px;
    border-radius: 5px;
    width: 40%;
    box-shadow: none;
    background-color: black !important;
    cursor: pointer;
}
#passwordError2 {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 14px;
    padding: 5px 0 5px 0;
}
</style>
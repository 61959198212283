<template>
  <div class="userPage2">
    <div class="profileItem3">
        <div class="profileHeader3">
            <h1>Simulations en cours</h1>
            <div v-if=" $store.state.status =='Administrateur'" id="switchSim">
              <div id="swithSubElt">
              <div class="btnSwitch btnLeft boundItem" :class="{poi: swithElt}" @click="swithElt=!swithElt">Locales</div>
              <div class="btnSwitch btnRight" :class="{poi: !swithElt}" @click="swithElt=!swithElt, getAllSims()">Serveur</div>
              </div>
            </div>
        </div>
        <div id="profileBody3">
                <table>
                  <thead>
                    <tr>
                      <th>Nom de la simulation</th>
                      <th>Statut</th>
                      <th>Position dans la file</th>
                    </tr>
                  </thead>
                  <tbody v-if="swithElt">
                    <tr v-for="sim in list" :key="sim.id">
                      <td >{{sim["name"]}}</td>
                      <td v-if="sim['state'] == 'PENDING'">En attente</td>
                      <td v-else-if="sim['state'] =='STARTED'">En cours</td>
                      <td v-if="sim['state'] =='STARTED' || sim.position<1">-</td>
                      <td v-else>{{sim["position"]}}</td>
                      <td><img class="stopSim" src="../../assets/fermer.png" @click="stopSim(sim['id'])"/></td>
                    </tr>
                    <tr v-if="list.length == 0">
                      <td colspan="4">Aucune simulation en cours</td>
                    </tr>
                  </tbody>
                   <tbody v-else>
                     <tr v-for="sim in listAdmin" :key="sim.id">
                      <td >{{sim["name"]}}</td>
                      <td v-if="sim['position'] =='-'">En cours</td>
                      <td v-else>En attente</td>
                      <td v-if="sim['position'] =='-'">-</td>
                      <td v-else>{{sim["position"]}}</td>
                      <td><img class="stopSim" src="../../assets/fermer.png" @click="stopSim(sim['id'])"/></td>
                    </tr>
                    <tr v-if="listAdmin.length == 0">
                      <td colspan="4">Aucune simulation en cours</td>
                    </tr>
                  </tbody>
                </table>
        </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import config from '../../../config.js'

export default {
    name:'simWaitList',
    data(){
      return {
        list:JSON.parse(localStorage.getItem("runningSim")),
        swithElt:true,
        listAdmin:[],
        listAdminTimeout:"",
      }
    },
    mounted() {
      this.$root.$on('addSim', (data) => {
        this.addSim(data)
      }),
      this.$root.$on('removeFinishedSim', (data) => {
        this.removeFinishedSim(data)
      }),
      this.$root.$on('updateSimState', (data) => {
        this.updateSimState(data)
      }),
      this.$root.$on('editListAfterStop', (id) => {
        this.editListAfterStop(id)
      })
    },
    beforeDestroy() {
      if (this.listAdminTimeout) {
        clearTimeout(this.listAdminTimeout);
      }
    },
    methods:{
      addSim(data){
        Vue.set(this.list, this.list.length, {"id":data.simId, "name":data.simName, "state":data.simState, "position":data.position});
      },
      updateSimState(data){
        var indexToUpdate = this.list.findIndex(function(sim){return sim.id ==  data.simId})
        if(this.list[indexToUpdate].state != "STARTED"){Vue.set(this.list[indexToUpdate], "state", data.simState)}
        if(data.position < this.list[indexToUpdate].position){Vue.set(this.list[indexToUpdate], "position", data.position)}
        // console.log("list", this.list)
      },
      removeFinishedSim(id){
        var indexToRemove = this.list.findIndex(function(sim){return sim.id ==  id})
        this.list.splice(indexToRemove, 1);
        //console.log(this.list)
      }, 
      stopSim(id){
        var vm = this
        axios.post(config.serverUrl+"/stop_sim/?id="+id)
        .then(function(){
            vm.$store.commit("EDIT_FEEDBACK_MSG", "Simulation arrêtée.")
            vm.$store.commit("DISPLAY_FEEDBACK_MODAL")
            if(vm.swithElt){vm.$parent.editSimCount(-1)}
            //actualiser les positions des autres sim
            vm.editListAfterStop(id)
            vm.$parent.getWaitingTime()
        })
        .catch(e=>{
            console.log(e);
            this.$store.commit("EDIT_FEEDBACK_MSG", "Un problème est survenu. (simWaitList.vue)")
            this.$store.commit("DISPLAY_FEEDBACK_MODAL")
        })
      },
      getAllSims(){
        var vm=this
        axios.get(config.serverUrl+"/get_all_tasks/")
        .then(response=>{
          this.listAdmin.splice(0)
          for (let index = 0; index < response.data.startedSim.length; index++) {
            this.listAdmin.push({"name":response.data.startedSim[index][0], "position":"-", "id":response.data.startedSim[index][1]})
          }
          for (let index = 0; index < response.data.pendingSim.length; index++) {
            this.listAdmin.push({"name":response.data.pendingSim[index][0], "position":index+1, "id":response.data.pendingSim[index][1]})            
          }
            this.listAdminTimeout = setTimeout(function(){
              vm.getAllSims()
            },5000); 
        })
        .catch(e=>{
            console.log(e);
        })
      },
      editListAfterStop(id){
        var indexToRemove
        if(this.swithElt) {
              indexToRemove = this.list.findIndex(function(sim){return sim.id ==  id})
              this.list.splice(indexToRemove, 1);
              localStorage.setItem("runningSim", JSON.stringify(this.list))
              for (let index = indexToRemove; index < this.list.length; index++) {
                Vue.set(this.list[index], "position", this.list[index].position-1)
                if(this.list[index].position <1){
                  Vue.set(this.list[index], "state", "STARTED")
                }
              } 
        }else{
          indexToRemove = this.listAdmin.findIndex(function(sim){return sim.id ==  id})
          this.listAdmin.splice(indexToRemove, 1)
              for (let index = indexToRemove; index < this.listAdmin.length; index++) {
                if(this.listAdmin[index].position == 1){
                  Vue.set(this.listAdmin[index], "position", "-")
                }
                if(this.listAdmin[index].position != "-"){
                  Vue.set(this.listAdmin[index], "position", this.listAdmin[index].position-1)
                }
              }
              // console.log("listAdmin", this.listAdmin)
        }
      },
    }
}
</script>

<style>
.profileItem3{
    position: absolute;
    margin: auto;
    top: 33px;
    right: 0;
    bottom: 0;
    left: 0;
    width:90%;
    height: fit-content;
    background-color: rgba(255, 255, 255, 0.7);
    overflow: auto;
    padding-bottom: 10px;
}
.profileHeader3{
    margin: auto;
    width: 98%;
    border-style: none none solid none;
    border-width:  0 0 1px 0;
} 
.profileHeader3> h1{
    margin-block-end: 0.5em;
}
#profileBody3{
    /* height: 442px;  */
    display: grid;
}
.linkBtn{
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
}
.stopSim{
  cursor: pointer;
}
#switchSim{
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.poi{
  background-color: #3b79bb;
  color:white
}
.btnSwitch{
  width:100px;
}
#swithSubElt{
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.boundItem{
  border: #3b79bb;
  border-style:none solid none none;
}
.btnRight{
  border-radius:0 10px 10px 0;
  border: 2px solid #3b79bb;
  user-select: none;
}
.btnLeft{
  border-radius:10px 0 0 10px;
  border: 2px solid #3b79bb;
  user-select: none;
}
@media (max-width: 650px) {
#profileBody3 {
    height: unset;
    margin-bottom: 10px;
}
.profileItem3 {
    /* position: sticky; */
    top: 150px;
    bottom:84px;
    max-height: 763px;
    overflow:auto;
    height: unset;
    padding-bottom: unset;
}
.userPage2{
    height: 80vh;
}
}
@-moz-document url-prefix() {
  /* .userPage2 {
    height: -moz-available !important;
  } */
  .profileItem3{
    height:60%;
  }
  .btnSwitch{
    padding-top:4px  
  }
}
</style>

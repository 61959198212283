export default {
    state: {
          sim_mgmt:{
            sim_duration:{name:"sim_duration", value:520, step:1},
            stab_duration:{name:"stab_duration", value:520, step:1},
            capacity:{name:"capacity", value:100, step:1},
            init_size:{name:"init_size", value:100, step:1}
          },
          prices:{
            MeatCull:{name:"MeatCull", value:2.5, step:0.1},
            MeatCalfs:{name:"MeatCalfs", value:70, step:1},
            heifersRTC:{name:"heifersRTC", value:1500, step:1},
            Straw:{name:"Straw", value:0.05, step:0.01},
            Milk1:{name:"Milk1", value:0.342421581044618, step:0.000000000000001},
            Milk2:{name:"Milk2", value:0.339395469300296, step:0.000000000000001},
            Milk3:{name:"Milk3", value:0.333345328024571, step:0.000000000000001},
            Milk4:{name:"Milk4", value:0.327306006780097, step:0.000000000000001},
            Vet1:{name:"Vet1", value:20, step:1},
            Vet2:{name:"Vet2", value:30, step:1},
            Vet3:{name:"Vet3", value:40, step:1},
            Insem:{name:"Insem", value:20, step:1},
            Cereal:{name:"Cereal", value:0.1834, step:0.0001},
            Soja:{name:"Soja", value:0.2541, step:0.0001},
            AMV:{name:"AMV", value:0.4, step:0.1},
            Cccalves:{name:"Cccalves", value:0.0531, step:0.0001},
            Pmilk:{name:"Pmilk", value:1.9, step:0.1},
            Emais:{name:"Emais", value:0, step:1},
            Foin:{name:"Foin", value:0, step:1}
          },
          med_prices:{
            Synulox_IntraM:{name:"Synulox_IntraM", value:138.25, step:0.01},
            duphamox_LA:{name:"duphamox_LA", value:54.42, step:0.01},
            Dexadreson:{name:"Dexadreson", value:32.16, step:0.01},
            Nuflor:{name:"Nuflor", value:59.09, step:0.01},
            draxxin100:{name:"draxxin100", value:479.5, step:0.1},
            Tolfine:{name:"Tolfine", value:59.78, step:0.01},
            Shotapen:{name:"Shotapen", value:34.29, step:0.01},
            Potencil:{name:"Potencil", value:52.5, step:0.1},
            stopM:{name:"stopM", value:120.71, step:0.01},
            Amphoprim:{name:"Amphoprim", value:20.08, step:0.01},
            colibolus:{name:"colibolus", value:572.88, step:0.01},
            Estrumate:{name:"Estrumate", value:53.36, step:0.01},
            Bioveine_GMC:{name:"Bioveine_GMC", value:14.76, step:0.01},
            bioveine_calcium:{name:"bioveine_calcium", value:20.18, step:0.01},
            bioveine_calcium2:{name:"bioveine_calcium2", value:20.18, step:0.01},
            Speciale2411:{name:"Speciale2411", value:8.96, step:0.01},
            rehydion:{name:"rehydion", value:36.69, step:0.01},
            Metricure:{name:"Metricure", value:206.56, step:0.01},
            lincocine:{name:"lincocine", value:87.08, step:0.01},
            Mastijet:{name:"Mastijet", value:100.49, step:0.01},
            Ubrolexin:{name:"Ubrolexin", value:171.86, step:0.01},
            MastiPeni:{name:"MastiPeni", value:60.67, step:0.01},
            MastiColi:{name:"MastiColi", value:39.02, step:0.01},
            clamoxyl_oblets:{name:"clamoxyl_oblets", value:139.28, step:0.01},
            calform_bolus:{name:"calform_bolus", value:65.47, step:0.01},
            CEPRAVIN:{name:"CEPRAVIN", value:147.78, step:0.01},
            ORBESEAL:{name:"ORBESEAL", value:121.76, step:0.01},
            delivrance_manuelle:{name:"delivrance_manuelle", value:0, step:1},
            no_trt:{name:"no_trt", value:0, step:1},
            roulage:{name:"roulage", value:0, step:1},
            chirurgie:{name:"chirurgie", value:0, step:1},
            parage_curatif:{name:"parage_curatif", value:0, step:1},
            pulverisation:{name:"pulverisation", value:0, step:1},
            Cyclospray:{name:"Cyclospray", value:0.14, step:0.01},
            Trimming:{name:"Trimming", value:15, step:1},
            Bandage:{name:"Bandage", value:0.60, step:0.01},
            CuSO45pcollectivefootbath:{name:"CuSO45pcollectivefootbath", value:39, step:1}
          }
        
    },
    mutations: {
      UPDATE_PARAM(state, newValue){
          state[newValue.paramType][newValue.item.name].value = newValue.item.value
      }
    }
  }

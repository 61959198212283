<template>
<div id ="head">
  <div id="fadeEffect" v-if="selected!='Période'"></div>
  <div id="left"  v-if="selected!='Période'">
    <button class="button4"  @click="swipeLeft()"><img class="btnImg" src="../assets/double-left-2.png"></button>
  </div>
  <div id="center" ref="content" v-bind:class="{periodSpace: !periodNotActive, otherPeriodSpace : periodNotActive}">

    <div class="timeNavSubDiv">
        <button class="buttonSelected" @click="back()">Retour</button>
          <label class="custom-select">
            <select @change="updateSelectedTime(selected)"  v-model="selected">
              <option value="Période">Période</option>
              <option value="Année" selected>Année</option>
              <option value="Mois">Mois</option>
              <option value="Semaine">Semaine</option>
            </select>
          </label>
    </div>
    <div class="timeNav" v-if="selected!='Période'">
      <div class="timeNavSubDiv">
          <button class="button2" :disabled='isDisabledBackward10' @click="moveTime(-10)">-10</button>
          <button class="button3" :disabled='isDisabledBackward1' @click="moveTime(-1)"><img src="../assets/icon_arrow.png" width="30px"></button>
          <div v-if="selected=='Mois'" class="timeItem">
            <input type="number" name="num" id="num" min="1" v-model.number="currentNum" @change="moveTimeInput()" v-bind:max="this.$store.state.maxMonth">
            <span>  / {{this.$store.state.maxMonth}}</span>
          </div>
          <div v-else-if="selected=='Année'" class="timeItem">
            <input type="number" name="num" id="num" min="1" v-model.number="currentNum" @change="moveTimeInput()" v-bind:max="this.$store.state.maxYear">
            <span>  / {{this.$store.state.maxYear}}</span>
          </div>
          <div v-if="selected=='Semaine'" class="timeItem">
            <input type="number" name="num" id="num" min="1" v-model.number="currentNum" @change="moveTimeInput()" v-bind:max="this.$store.state.maxWeek">
            <span>  / {{this.$store.state.maxWeek}}</span>
          </div>
          <button class="button3"  :disabled='isDisabledForward1' @click="moveTime(1)"><img src="../assets/icon_arrow_right.png" width="30px"></button>
          <button class="button2" :disabled='isDisabledForward10' @click="moveTime(10)">+10</button>
      </div >
      <div class="timeNavSubDivBtn">
          <button v-if="!play" :disabled='isDisabledPlay' class="buttonPlay" @click="play = true, animation()"><img src="../assets/icon_play.png" width="30px"></button>
          <button v-else class="buttonPlay" @click="play = false, pause()"><img src="../assets/icon_pause.png" width="30px"></button>
      </div>
      <div class="timeNavSubDivBtn">
          <button class="button" @click="reset()">Reset</button>
      </div>

    </div>
    <!-- <div class="timeNavSubDivRight">
        <img src="../assets/IMG_PictoVacheFichier 41.png" title="Taille du cheptel adulte" width="25px">   
        <span><p>{{this.$store.state.result.Flux.Ani_Count}}</p></span>
    </div> -->
  </div>
  <div id="right"  v-if="selected!='Période'" >
    <button class="button4"  @click="swipeRight()"><img class="btnImg" src="../assets/double-right-2.png"></button>
  </div>
</div>
</template>

<script>
// import json from '../../public/widgets.json'

export default {
  name: 'Header',
    data(){
     return {
       periodNotActive:true,
       selected:"Année",
       currentNum:1,
       play:false,
       playLoop:'',
       widgets:"",
       isDisabledPlay:false,
       isDisabledBackward10:true,
       isDisabledBackward1:true,
       isDisabledForward1:false,
       isDisabledForward10:false,

     }
  },
  async mounted(){
        this.$store.commit('MOVE_TIME', 1)
        this.$store.commit('UPDATE_SELECTED_TIME', "Année")
        this.widgets = this.$parent.jsonWidgetDoc
  },
  methods:{
    back(){
      this.$store.commit("BACK_TO_PARAMS")
    },
    updateSelectedTime(variable){
      this.$store.commit('UPDATE_SELECTED_TIME', variable)
      this.reset()
      variable == "Période" ? this.periodNotActive = false : this.periodNotActive = true
      if (variable == "Année" && this.$store.state.maxYear < 11 ) {
        this.isDisabledForward10 = true
      } else{
        this.isDisabledForward10=false
      }
      this.$parent.chartReflow()
    },
    moveTime(number){
      this.validation(number)
      this.$parent.getCurrentIndicators()
      this.$parent.chartEdit(this.widgets)
    },
    moveTimeInput(){
      // if(this.selected == "Année" && this.currentNum > this.$store.state.maxYear || this.selected == "Semaine" && this.currentNum > this.$store.state.maxWeek || this.selected == "Mois" && this.currentNum > this.$store.state.maxMonth ){
      //   console.log("Choix invalide")
      // } else {
      this.validation(0)
      this.$store.commit('MOVE_TIME', this.currentNum)
      this.$parent.getCurrentIndicators()
      this.$parent.chartEdit(this.widgets)
      //}
    },
    animation(){
      this.play = true
      let vm = this
      this.playLoop = setInterval(
        function(){
          if (vm.selected == "Année" && vm.currentNum == vm.$store.state.maxYear || vm.selected == "Mois" && vm.currentNum == vm.$store.state.maxMonth ||vm.selected == "Semaine" && vm.currentNum == vm.$store.state.maxWeek){
            vm.isDisabledPlay = true
            vm.isDisabledForward1=true
            vm.pause()
          } else{
            vm.currentNum += 1
            vm.$store.commit('FORWARD', 1)
            vm.$parent.getCurrentIndicators()
            vm.$parent.chartEdit(vm.widgets)
          }
        }, 1000
      )
    },
    pause(){
      this.play = false
      clearInterval(this.playLoop)
    },
    reset(){
      this.currentNum = 1
      this.isDisabledPlay = false
      this.isDisabledForward1=false
      this.isDisabledForward10=false
      this.isDisabledBackward1=true
      this.isDisabledBackward10=true
      this.$store.commit('MOVE_TIME', 1)
      this.$parent.getCurrentIndicators()
      this.$parent.chartEdit(this.widgets)
    },
    validation(number){
        let validate = this.currentNum + number
        if (validate <= 1){
          this.currentNum = 1
          this.$store.commit('MOVE_TIME', 1)
          this.isDisabledBackward1=true
        } else if (this.selected == "Année" && validate >= this.$store.state.maxYear){
          this.currentNum = this.$store.state.maxYear
          this.isDisabledPlay = true
          this.$store.commit('MOVE_TIME', this.$store.state.maxYear)
          this.isDisabledForward1=true
          this.isDisabledForward10=true
        } else if (this.selected == "Mois" && validate >= this.$store.state.maxMonth){
          this.currentNum = this.$store.state.maxMonth
          this.isDisabledPlay = true
          this.$store.commit('MOVE_TIME', this.$store.state.maxMonth)
          this.isDisabledForward1=true
          this.isDisabledForward10=true
        } else if (this.selected == "Semaine" && validate >= this.$store.state.maxWeek){
          this.currentNum = this.$store.state.maxWeek
          this.isDisabledPlay = true
          this.$store.commit('MOVE_TIME', this.$store.state.maxWeek)
          this.isDisabledForward1=true,
          this.isDisabledForward10=true
        } else{
          this.currentNum += number
          this.$store.commit('FORWARD', number)
          this.isDisabledPlay = false
          this.isDisabledForward1=false,
          this.isDisabledForward10=false
          this.isDisabledBackward1=false
        }
      
      if(this.currentNum > 10){
        this.isDisabledBackward10=false
      } else{
        this.isDisabledBackward10=true
      }

      if(this.currentNum > 1){
        this.isDisabledBackward1=false
      } else{
        this.isDisabledBackward1=true
      }
      
      if (this.selected == "Année" && this.currentNum+1 > this.$store.state.maxYear || this.selected == "Mois" && this.currentNum+1 > this.$store.state.maxMonth|| this.selected == "Semaine" && this.currentNum+1 > this.$store.state.maxWeek) {
        this.isDisabledForward1=true
        this.isDisabledPlay = true
      }else  if (this.selected != "Période" ){
        this.isDisabledForward1=false
        this.isDisabledPlay = false
      }

      if (this.selected == "Semaine" && this.currentNum+10 > this.$store.state.maxWeek || this.selected == "Mois" && this.currentNum+10 > this.$store.state.maxMonth|| this.selected == "Année" && this.currentNum+10 > this.$store.state.maxYear) {
        this.isDisabledForward10=true
      } else if (this.selected != "Période") {
        this.isDisabledForward10=false
      }
    },
    scrollTo(element, scrollPixels, duration) {
      var test
      const scrollPos = element.scrollLeft;
      // Condition to check if scrolling is required
      if ( !( (scrollPos === 0 || scrollPixels > 0) && (element.clientWidth + scrollPos === element.scrollWidth || scrollPixels < 0))) 
      {
        // Get the start timestamp
        const startTime =
          "now" in window.performance
            ? performance.now()
            : new Date().getTime();
        
        test = function scroll(timestamp) {
          //Calculate the timeelapsed
          const timeElapsed = timestamp - startTime;
          //Calculate progress 
          const progress = Math.min(timeElapsed / duration, 1);
          //Set the scrolleft
          element.scrollLeft = scrollPos + scrollPixels * progress;
          //Check if elapsed time is less then duration then call the requestAnimation, otherwise exit
          if (timeElapsed < duration) {
            //Request for animation
            window.requestAnimationFrame(test);
          } else {
            return;
          }
        }
        //Call requestAnimationFrame on scroll function first time
        window.requestAnimationFrame(test);
      }
    },
    swipeLeft() {
      const content = this.$refs.content;
      this.scrollTo(content, -300, 800);
    },
    swipeRight() {
      const content = this.$refs.content;
      this.scrollTo(content, 300, 800);
    }
  }
}
</script>

<style>
#head{
  display: flex;
  position: fixed;
  height: 100px;
  width: 100%;
  z-index:2;
  background-color: white;
  justify-content: space-between;
  place-items: center;
  border-style: none none solid none;
  border-width:  0 0 2px 0;
}

#center{
  display: flex;
  height: 100px;
  justify-content: space-between;
  white-space: nowrap;
  overflow-x: auto;
  padding-left: 20px;
}
.periodSpace{
  width: 100%;
}
.otherPeriodSpace{
  width: 100%;
}
#right, #left{
  display:none;
}
.timeNav{
  width: 100%;
  display : flex;
  justify-content: space-around;
}
.timeNavSubDiv, .timeNavSubDivRight, .timeNavSubDivBtn{
  display : flex;
  align-items: center;
}
.timeNavSubDivRight span{
  margin-right:18px;
}
.timeItem{
  height: 52px;
  display: flex;
  align-items : center;
  background-color: #0062cc;
  border: none;
  border-radius: 10px;
  color: white;
  padding: 0px 16px;
  text-decoration: none;
  font-size: 16px;
  margin: 4px 2px;
}
input[type="number"] {
  width: 40px;
  margin-right: 3px;
}
.buttonSelected, .button2, .button3, .buttonPlay{
  background-color: #003772;
  border: none;
  border-radius: 10px;
  color: white;
  padding: 15px 30px;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  margin: 4px 2px;
  cursor: pointer;
}
.button2{
  background-color: #3b79bb;
}
.button3{
  background-color: #97c1ee;
  padding: 8px 32px;
}
.button4{
  background-color: transparent;
  border: none;
  padding: unset;
}
.btnImg{
  width:50px;
}
.buttonPlay{
  padding: 8px 32px;
}
button:disabled, button[disabled]{
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
  cursor: default;
}
.custom-select {
  width: 130px;
  position: relative;
  display: block;
  margin: 4px 2px;
  border: 1px solid white;
  z-index: 10;
  background-color: #0062cc;
  border-radius: 10px;
}
.custom-select:after {
  position: absolute;
  right: 0;
  top: 0;
  width: 37px;
  height: 100%;
  line-height: 45px !important;
  /* content: "∨"; */
  content: url("../assets/down-arrow.png");
  text-align: center;
  color: white;
  font-size: 22px;
  border-left: 1px solid white;
  padding-top: 8px;
  z-index: -1;
}
option {
  color:black;
}
select {
  height: 100%;
  border: none;
  outline: none;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  margin: 0;
  display: block;
  width: 100%;
  font-size: 18px;
  cursor: pointer;
  color: white;
  align-items : center;
  padding: 15px 13px;
}

@media (max-width: 1390px) {
#fadeEffect{
  position: fixed;
  top: 0;
  left: 7.8%;
  height: 100px;
  width: 84.4%;
  pointer-events: none;
  background-image:linear-gradient(to left, white 0%, rgba(255, 255, 255, 0) 3%, rgba(255, 255, 255, 0) 98%, white 100%);
  background-image:-moz-linear-gradient(to left, white 0%, rgba(255, 255, 255, 0) 3%, rgba(255, 255, 255, 0) 98%, white 100%);
  z-index: 1000;
}
#right, #left{
  display:flex;
  width: 8%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.button4:hover{
  cursor: pointer;
}
#right{
  z-index: 999;
}
.timeNav{
  margin-left: 30px;
  width:auto;
  justify-content: unset;
}
.timeNavSubDivRight {
  margin-left: 30px;
}
.timeNavSubDivBtn{
  margin-left: 20px;
}
.otherPeriodSpace{
  width: 83%;
}
}
@media (max-width: 600px) {
.btnImg{
  width:100%;
}
}
</style>

<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
        <div class="modal-container">
          <img id="imgwait2" src="../../assets/output_c5hJL0.gif">
          <p> Votre simulation {{$store.state.newSimAvailableName}} en terminée. Souhaitez-vous l'afficher?</p>
          <button class="button simBtns" @click="displaySim($store.state.newSimAvailableName)">Oui</button>
          <button class="button simBtns" @click="close()">Non</button>
        </div>
        </div>
    </div>
</template>

<script>
export default {
  name:"simLoadingModal",
  data(){
    return {

      
    }
  },
  methods:{
        close(){
            this.$store.commit("HIDE_SIM_AVAILABLE_MODAL")
        },
        async displaySim(name){
          console.log("name", name)
          await this.close()
          await this.$store.commit("BACK_TO_PARAMS")
          this.$parent.getPreviousScenario(name)
        }
    }
} 
</script>

<style scoped>
img{
    width: 90%;
    height: 86%;
    padding-bottom: 10px;
    object-fit: fill;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-container {
  width: 300px;
  height: auto;
  margin: 0px auto;
  padding: 23px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  overflow:auto;
}
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
p{
  font-size:12px;
  text-indent:0px;
}
#imgwait2{
    width: 90%;
    height: 86%;
    padding-bottom: 10px;
    -o-object-fit: fill;
    object-fit: fill;
}
.simBtns{
  font-size: 14px;
  padding: 11px 22px;
}
.button{
  background-color: #0062cc;
  border: none;
  border-radius: 10px;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}
@media (max-width: 700px) {
.modal-container {
  width: 50%;
  height: unset;
}
}
@media (max-width: 400px) {
.modal-container {
  width: 80%;
  height: unset;
}
}
</style>